<template>
  <div class="container">
    <div class="banner">
      <img src="https://fxwebsite.rencaijituan.com/global/news/banner.jpg" alt="banner">
    </div >
    <!-- <div class="nav">
      <el-menu :default-active="activeIndex" mode="horizontal" text-color="#000000D9" active-text-color="#00ADF6" router>
        <el-menu-item index="/news/newsList/1">机构动态</el-menu-item>
        <el-menu-item index="/news/newsList/2">时事咨询</el-menu-item>
        <el-menu-item index="/news/newsList/3">国家政策</el-menu-item>
      </el-menu>
    </div>  -->
    <div class="main">
      <router-view></router-view>
    </div>
    
  </div>
</template>
<script>
  
  export default {
    
  }
</script>
<style lang="scss" scoped>
  $my_padding: percentage(260/1920);
  .container {
    margin-top: 100px;
    .banner {
      height: 25vw;
      > img {
        width: 100%;
      }
    }
    
  }
</style>
  