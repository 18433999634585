<template>
  <div class="container">
    <banner :banner-list="bannerList" />
    <div class="nav">
      <el-menu :default-active="activeIndex" mode="horizontal" text-color="#000000D9" active-text-color="#00ADF6" router>
        <el-menu-item index="/homeCare/introduce">系统介绍</el-menu-item>
        <el-menu-item index="/homeCare/offer">系统报价</el-menu-item>
        
      </el-menu>
    </div>
    <div class="main">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
  import Banner from '@/components/banner/Banner.vue';
  /* import Vue from 'vue';
  import { Menu, MenuItem } from 'element-ui';
  Vue.use(Menu);
  Vue.use(MenuItem); */
  export default {
    data() {
      return {
        // activeIndex: this.$route.path,
        bannerList: {
          src: '/global/homecare/banner.jpg',
          height: '15.2083 vw',
          list: [
            {
              icon: 'JG',
              title: '数据信息化、智能监管',
              content: '老人档案、服务商管理、员工管理、服务工单管理、政府采购管理等系统实现数据信息化，以系统数据为依据更好地监管、分配居家服务资源，提高居家养老服务质量。'
            },
            {
              icon: 'YJ',
              title: '智能设备监测健康安全数据',
              content: '通过使用配套居家智能设备，实现24小时监测老人健康数据和定位安全情况；数据实时自动上传，系统接收到异常情况及时发出警报，促使老人及时就医，为医生诊断提供数据依据。'
            },
            {
              icon: 'Data1',
              title: '数据智能统计分析',
              content: '服务工单、会员交易消费等运营数据统计分析可视化，为运营服务查证监管做支撑；老人档案信息、智能设备健康数据统计分析，为老人健康保障做依据。'
            }
          ]
        },
      };
    },
    components: {
      Banner
    },
    computed: {
      activeIndex() {
        const { name } = this.$route;
        switch(name) {
          case 'introduce':
            return '/homeCare/introduce';
          case 'offer':
            return '/homeCare/offer';
        }
      },
    
    },
    mounted() {
      // console.log(this.$route.path);
      // console.log(this.activeIndex);
      // this.$router.go(0);
    },
    
  }
</script>

<style lang="scss" scoped>
  $my_padding: percentage(260/1920);
  .container {
    .nav {
       margin-top: 120px;
       padding: 0 $my_padding;
      //  background: red;
      .el-menu {
        // padding: 0 10vw;
        display: flex;
        justify-content: space-between;
        // display: block;
        .el-menu-item {
          display: block;
          position: relative;
          font-size: 1.25vw;
          font-weight: 500;
          font-style: normal;
          color: rgba(0, 0, 0, 0.85);
          &:hover {
            color: #00ADF6 !important;
          }
           &.is-active {
            border-bottom: rgba(0, 0, 0, 0) !important;
            // font-weight: 500;
            &::after {
              content: '';
              height: 4px;
              width: 3.3333vw;
              background-color: #00ADF6;
              position: absolute;
              bottom: 0;
              left: 50%;
              margin-left: -1.6667vw;
            }
          }
        }
      }
    }
  }
</style>