<template>
    <div class="container">
      <!-- banner组件 -->
        <banner :banner-list="bannerList" />
        <div class="call-center">
          <div class="call-l">
            <div class="call-title">呼叫中心</div>
            <p>利用语音网关、话务设备搭建基础呼叫系统，集成养老服务热线，保证市民只要拨打养老服务热线，足不出户就能享受到家政服务、医疗救助、配送服务、餐饮服务等方便快捷、细致周到的健康养老服务。须包括呼叫中心话务系统、服务调度管理系统、养老服务政策和知识库管理系统等系统，实现对服务的全流程闭环管理。</p>
          </div>
          <div class="call-r">
            <div class="call-item">
               <svg-icon icon-class="YYWG"></svg-icon>
               <p>语音网关</p>
            </div>
            <div class="call-item">
               <svg-icon icon-class="GDXT"></svg-icon>
               <p>工单系统</p>
            </div>
            <div class="call-item">
               <svg-icon icon-class="DDGL"></svg-icon>
               <p>调度管理</p>
            </div>
            <div class="call-item">
               <svg-icon icon-class="ZSK"></svg-icon>
               <p>知识库</p>
            </div>
          </div>
        </div>
        <div class="elderly-university">
          <div class="university-item">
            <svg-icon icon-class="Label"></svg-icon>
            <div class="university-title">
              日程/课室管理
            </div>
            <p>满足多个学习分课室、不同日程的管理需要，兼顾日程提示、日程时间、日程内容等功能，确保活动的有序进行。</p>
          </div>
          <div class="university-item">
            <svg-icon icon-class="Label"></svg-icon>
            <div class="university-title">
              参会报名
            </div>
            <p>支持个人及团队在线报名参会，操作简单易上手，提升用户操作体验。</p>
          </div>
          <div class="university-item">
            <p>线上老人大学</p>
          </div>
          <div class="university-item">
            <svg-icon icon-class="Label"></svg-icon>
            <div class="university-title">
              短信群发推广
            </div>
            <p>课程可配置线上直播、点播，线上线下同时进行，直播结束后实时生成回放视频。</p>
          </div>
          <div class="university-item">
            <svg-icon icon-class="Label"></svg-icon>
            <div class="university-title">
              视频观看
            </div>
            <p>课程可配置线上直播、点播，线上线下同时进行，直播结束后实时生成回放视频。</p>
          </div>
          <div class="university-item">
            <svg-icon icon-class="Label"></svg-icon>
            <div class="university-title">
              资料下载
            </div>
            <p>课程活动相关资料信息可上传到课室中，支持上传多种文件格式；展示与课程页面便于参课人员下载查阅。</p>
          </div>
        </div>
    </div>
</template>
<script>
  import Banner from '@/components/banner/Banner.vue'
  export default {
    data() {
      return {
        bannerList: {
          src: '/global/civil/banner.jpg',
          height: '13.5417vw',
          list: [
            {
              icon: 'keshihua',
              title: '中央控制平台(数据可视化监控)',
              content: '辖区内养老状况，包括居家养老数据、机构养老数据、养老服务数据、老人服务位置数据、养老综合数据等全面可视化监控。'
            },
            {
              icon: 'butie',
              title: '补贴发放管理监管',
              content: '补贴标准设置、发放、申请、审核，以及补贴记录的查询汇总；全流程数据自动上报及监督，一目了然。'
            },
            {
              icon: 'servic',
              title: '服务管理监管',
              content: '养老服务商数据、服务人员数据、服务工单数据等监控，保障老人服务需求匹配、落实。'
            }
          ]
        },
      };
    },
    components: {
      Banner
    }
  }
</script>

<style lang="scss" scoped>
  $my_padding: percentage(260/1920);
  .container {
      .call-center {
        margin-top: 136px;
        height: 24.375vw;
        background: url('../assets/global/civil/bg.png') no-repeat center center;
        background-size:100% 100%;
        padding: 0 $my_padding;
        display: flex;
        .call-l {
          width: 22.9167vw;
          background: #FFFFFF;
          height: inherit;
          box-sizing: border-box;
          padding: 3.3333vw 2.0833vw;
          .call-title {
            font-size: 1.6667vw;
            font-weight: 500;
            color: rgba(0, 0, 0, 0.85);
            margin-bottom: 1.25vw;
          }
          > p {
            font-size: 0.8333vw;
            font-weight: 400;
            line-height: 2vw;
            color: rgba(0, 0, 0, 0.6);
          }
        }
        .call-r {
          flex: 1;
          display: flex;
          .call-item {
            text-align: center;
            flex: 1;
            border-left: 1px solid rgba(255, 255, 255, 0.3);
            .svg-icon {
              width: 2.9167vw;
              height: 2.9167vw;
              // margin-bottom: 0.8333vw;
              margin: 16.0417vw 0 0.8333vw 0;
            }
            > p {
              font-size: 0.8333vw;
              font-weight: 500;
              color: #FFFFFF;
            }
          }
        }
      }
      .elderly-university {
        margin-top: 120px;
        // height: 25vw;
        margin-bottom: 80px;
        padding: 0 $my_padding;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        .university-item {
          position: relative;
          width: 22.9167vw;
          height: 11.4583vw;
          background: rgba(0, 173, 246, 0.04);
          border-radius: 8px;
          padding: 1.6667vw 2.0833vw;
          box-sizing: border-box;
          margin-bottom: 2.0833vw;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          .svg-icon {
            width: 1.7708vw;
            height: 2.1875vw;
            position: absolute;
            top: 0;
            right: 2.0833vw;
          }
          .university-title {
            font-size: 1.25vw;
            font-weight: 500;
            color: rgba(0, 0, 0, 0.85);
          }
          > p {
            font-size: 0.8333vw;
            font-weight: 400;
            line-height: 1.6667vw;
            color: rgba(0, 0, 0, 0.6);
          }
          &:nth-child(3) {
            background: url('../assets/global/civil/university.png') no-repeat center center;
            background-size:100% 100%;
            padding: 0;
            > p {
              text-align: right;
              font-size: 1.875vw;
              font-weight: 500;
              color: rgba(0, 0, 0, 0.85);
            }
          }
        }
      }
  }
</style>