<template>
  <div class="detail-container">
    <div class="breadcrumb">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item @click.native="toBack()">新闻资讯</el-breadcrumb-item>
        <el-breadcrumb-item>资讯详情</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="detail-main">
      <div class="detail-header">
        <div class="detail-title">{{newsData.newsTitle}}</div>
        <p>发表时间：{{newsData.newsPublishDate}}</p>
      </div>
      <div class="detail-body" v-html="newsData.newsDetail"></div>
    </div>
    
  </div>
</template>

<script>
  import Vue from 'vue';
  import { Breadcrumb, BreadcrumbItem } from 'element-ui'
  Vue.use(Breadcrumb);
  Vue.use(BreadcrumbItem);
  export default {
    props: ['id'],
    data() {
      return {
        newsData: {},
      };
    },

    mounted() {
      // console.log(this.id);
      this.getNewsDetail();
    },
    
    methods: {
      toBack() {
        this.$router.go(-1);
      },
      async getNewsDetail() {
        let result = await this.$http.get({
            url: `/client/web/news/details/${this.id}`
            
        })
        
        let { data } = result;
        // console.log(rows);
        this.newsData = data;
        // console.log(this.newsData);
      }
    },
  }
</script>

<style lang="scss" scoped>
  $my_padding: percentage(260/1920);
  .detail-container {
    padding: 0 $my_padding;
    margin-top: 40px;
    .breadcrumb {
      .el-breadcrumb {
        font-size: 0.8333vw;
        font-weight: 400;
        ::v-deep .el-breadcrumb__item {
          .el-breadcrumb__inner {
            color: rgba(0, 0, 0, 0.6) !important;
            cursor: pointer;
          }
          &:last-child {
            .el-breadcrumb__inner {
              color: rgba(0, 0, 0, 0.85) !important;
              cursor: default;
            }
          }
        }
        /* .el-breadcrumb__item:last-child {
          color: rgba(0, 0, 0, 0.85);
        } */
      }
    }
    .detail-main {
      margin: 64px 0 120px 0;
      .detail-header {
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        .detail-title{
          font-weight: 500;
          font-size: 1.25vw;
          color: rgba(0, 0, 0, 0.85);
          margin-bottom: 1.25vw;

        }
        > p {
          font-weight: 400;
          font-size: 0.8333vw;
          color: rgba(0, 0, 0, 0.6);
          margin-bottom: 2.1875vw;
        }
      }
      .detail-body {
        margin-top: 2.1875vw;
        ::v-deep p {
          line-height: 2vw !important;
          img {
            max-width: 100%;
          }
        }
      }
    }
  }
</style>