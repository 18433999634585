<template>
  <div class="container">
    <banner :banner-list="bannerList" />
    <div class="nav">
      <el-menu :default-active="activeIndex" mode="horizontal" text-color="#000000D9" active-text-color="#00ADF6" router>
        <el-menu-item index="/residential/rIntroduce/1">系统介绍</el-menu-item>
        <el-menu-item index="/residential/rOffer">系统报价</el-menu-item>
        
      </el-menu>
    </div>
    <div class="main">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
  import Banner from '@/components/banner/Banner.vue';
  export default {
    data() {
      return {
        // activeIndex: this.$route.path,
        bannerList: {
          src: '/global/residential/banner.jpg',
          height: '13.5417vw',
          list: [
            {
              icon: 'guobiao',
              title: '紧跟国标规范',
              content: '以养老机构实际管理和服务需求为出发点，按照国标《养老机构服务安全基本规范》和《长期护理失能等级评估标准》设计研发。'
            },
            {
              icon: 'duoduan',
              title: '信息化、无纸化',
              content: '助力养老机构的护理管理、健康数据管理、评估管理、床位管理、员工管理等完全实现信息化、无纸化，简化工作流程，提高工作效率。'
            },
            {
              icon: 'duoduan-1',
              title: '信息平台多端适配',
              content: '信息平台多端（护士站显示屏、护士/护工平板、老人床前平板、老人房前平板、员工手机端、家属手机端、数据可视化大屏等）适配。'
            }
          ]
        },
      };
    },
    components: {
      Banner
    },
    computed: {
      //处理菜单选择状态
      activeIndex() {
        const { name } = this.$route;
        switch(name) {
          case 'rIntroduce':
            return '/residential/rIntroduce/1';
          case 'rOffer':
            return '/residential/rOffer';
        }
      },
    
    },
  }
</script>

<style lang="scss" scoped>
  $my_padding: percentage(260/1920);
  .container {
    .nav {
       margin-top: 120px;
       padding: 0 $my_padding;
      //  background: red;
      .el-menu {
        // padding: 0 10vw;
        display: flex;
        justify-content: space-between;
        // display: block;
        .el-menu-item {
          display: block;
          position: relative;
          font-size: 1.25vw;
          font-weight: 500;
          font-style: normal;
          color: rgba(0, 0, 0, 0.85);
          &:hover {
            color: #00ADF6 !important;
          }
           &.is-active {
            border-bottom: rgba(0, 0, 0, 0) !important;
            // font-weight: 500;
            &::after {
              content: '';
              height: 4px;
              width: 3.3333vw;
              background-color: #00ADF6;
              position: absolute;
              bottom: 0;
              left: 50%;
              margin-left: -1.6667vw;
            }
          }
        }
      }
    }
  }
</style>