<template>
  <div class="container">
    <!-- <div class="banner">
      <img src="https://fxwebsite.rencaijituan.com/global/home/banner.jpg" alt="banner">
    </div> -->
    <swiper :src='src' />
    
    <!-- <div class="title">
       <div class="title-l">
         <p>全场景医养服务</p>
         <p>智慧管理平台</p>
       </div>
       <div class="title-r">
         <p>集机构、居家、社区、旅居等各种养老类型</p>
         <p>同时满足医院、区域民政管理等不同层级的管理需要而建立的医养服务智慧管理平台</p>
       </div>
    </div> -->
	<div class="invest-project">
	  <div class="invest-title">
	    <span>繁星医养投资项目</span>
	    <span>携手合作.共创医养结合</span>
	    
	  </div>
	  <div class="invest-content">
	    <div>
	      <img src="@/assets/global/home/cooperate-img.jpg" alt="">
	    </div>
	    
	    <div>
	      <p>广东繁星医养集团根据符合投资条件、有意向在养老机构自身地方投资医院的合作伙伴提供资金、运营等一站式服务综合投资项目，最终打造机构为医养一体综合机构体系，达到双赢一种合作模式。（项目同样适用于医院投资养老机构）</p>
	      <a href="/invest">了解更多<i class="el-icon-sort-up"></i></a>
	    </div>
	  </div>
	</div>
    <div class="service-introduce">
      <div class="residential">
        <img src="https://fxwebsite.rencaijituan.com/global/home/home-residential.png" alt="">
        <p class="res-title">智慧院舍系统</p>
        <p class="res-content">以养老机构实际管理和服务需求为出发点，按照国标《养老机构服务安全基本规范》和《长期护理失能等级评估标准》设计研发；助力养老机构的管理、护理服务、健康数据等完全实现信息化、无纸化；信息平台更是多端 (护士站显示屏、护士/护工平板、老人床前平板、老人房前平板、员工手机端、家属手机端、数据可视化大屏等) 适配，随时随地了解机构养老管理、服务、健康数据。</p>
        <span><a href="/residential">了解更多<i class="el-icon-sort-up"></i></a></span>
      </div>
      <div class="homecare">
        <img src="https://fxwebsite.rencaijituan.com/global/home/home-homecare.png" alt="">
        <p class="res-title">智慧居家养老系统</p>
        <p class="res-content">以服务居家老人为出发点、社区为依托、智能设备和呼叫中心为纽带，结合互联网、物联网、智能设备打造以“呼叫救助、居家照料、健康服务、上门送餐、档案管理”等为主的智能居家养老系统，帮助居家养老服务组织者获取服务信息提供了紧急呼叫、跌倒报警、远程定位、安防监控、生活需求、健康管理等智能数据。</p>
        <span><a href="/homeCare">了解更多<i class="el-icon-sort-up"></i></a></span>
      </div>
      <div class="civil">
        <img src="https://fxwebsite.rencaijituan.com/global/home/home-civil.png" alt="">
        <p class="res-title">民政服务系统</p>
        <p class="res-content">结合互联网技术，形成数据可视化大屏展现，政府全面掌握辖区内养老状况，包括居家养老数据、机构养老数据、养老服务数据、老人服务位置数据、养老综合数据等，便于决策及分析，合理规划并监管养老服务资源。</p>
        <span><a href="/civilService">了解更多<i class="el-icon-sort-up"></i></a></span>
      </div>
    </div>
    <div class="tips">
      <div class="tips-item">
        <div>
          <span>50</span><span>+</span>
          <p>服务机构超过50家</p>
          <i><svg-icon icon-class="mechanism"></svg-icon></i>
        </div>
        
      </div>
      <div class="tips-item">
        <div>
          <span>30</span><span>+</span>
          <p>投入硬件超过30种</p>
          <i><svg-icon icon-class="hardware"></svg-icon></i>
        </div>
      </div>
      <div class="tips-item">
        <div>
          <span>10</span><span>+</span>
          <p>合作伙伴超过10家</p>
          <i><svg-icon icon-class="cooperate"></svg-icon></i>
        </div>
        
      </div>
      <div class="tips-item">
        <div>
          <span>30</span><span>+</span>
          <p>在申请专利和著作权超过30项</p>
          <i><svg-icon icon-class="patent"></svg-icon></i>
        </div>
        
      </div>
    </div>
    <div class="hardware-product">
      <div class="product-title">
        <span>智能硬件</span>
        <a href="/hardware">查看全部产品<i class="el-icon-sort-up"></i></a>
      </div>
      <div class="product-content">
        <div class="product-items">
          <div>
            <p>智能血糖仪</p>
            <p>酮体警示功能，约 0.7 微升采血量，5 秒出结果，安全、快速、专业精准；云端数据管理，便捷记录，永久保存。</p>
          </div>
          <img src="@/assets/global/home/product-img1.png" alt="">
        </div>
        <div class="product-items">
          <div>
            <p>智能血压计</p>
            <p>全自动语音提示操作；双示波法，升降测量，两次平均法，测量更准确；自动检测脉搏信号，心率不齐提示。</p>
          </div>
          <img src="@/assets/global/home/product-img2.png" alt="">
        </div>
        <div class="product-items">
          <div>
            <p>红外滞留探测仪</p>
            <p>可实现生命体征长时间滞留报警；长时间无生命体征信号报警；且可自适应低于 4 摄氏度/分钟环境温度的变化场景。</p>
          </div>
          <img src="@/assets/global/home/product-img3.png" alt="">
        </div>
        <div class="product-items">
          <div>
            <p>燃气报警器</p>
            <p>环境中可燃气体浓度达到设定阈值时，发出声光报警信号。通过蓝牙网关收集报警信号，上报监测系统，并通知管理人员处理。</p>
          </div>
          <img src="@/assets/global/home/product-img4.png" alt="">
        </div>
      </div>
    </div>
    <!-- <div class="invest-project">
      <div class="invest-title">
        <span>繁星医养投资项目</span>
        <span>携手合作.共创医养结合</span>
        
      </div>
      <div class="invest-content">
        <div>
          <img src="@/assets/global/home/cooperate-img.jpg" alt="">
        </div>
        
        <div>
          <p>广东繁星医养集团根据符合投资条件、有意向在养老机构自身地方投资医院的合作伙伴提供资金、运营等一站式服务综合投资项目，最终打造机构为医养一体综合机构体系，达到双赢一种合作模式。（项目同样适用于医院投资养老机构）</p>
          <a href="/invest">了解更多<i class="el-icon-sort-up"></i></a>
        </div>
      </div>
    </div> -->
  </div>
</template>
<script>
  import Vue from 'vue';
  import { Icon } from 'element-ui'
  Vue.use(Icon);
  import Swiper from '@/components/swiper/Swiper.vue'
  export default {
    data() {
      return {
        src: {
          src: '/global/home/banner.jpg'
        }
      };
    },
    components: {
        Swiper
    }
  }
</script>

<style lang="scss" scoped>
  $my_padding: percentage(260/1920);
  .container {
    margin-top: 100px;
    /* .banner {
      width: 100%;
      // height: 400px;
      > img {
        width: 100%;
        height: 100%;
      }
    } */
    .title {
      padding: 0 $my_padding;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 117px;
      // border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      .title-l {
        > p {
          font-size: 1.667vw;
          font-weight: 500;
          color: rgba(0, 0, 0, 0.85);
          margin-top: 0.7292vw;
          width: 16.458vw;
        }
      }
      .title-r {
        flex: 1;
        height: 100px;
        padding-top: 20px;
        box-sizing: border-box;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        > p {
          margin-top: 14px;
          font-size: 16px;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.6);
          text-align: right;
        }
        
        
      }
    }
    .service-introduce {
      padding: 0 $my_padding;
      // box-sizing: border-box;
      margin-top: 67px;
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      // flex-wrap: wrap;
      > div {
        box-sizing: border-box;
        width: 22.9167vw;
        border-radius: 8px;
        // min-width: 400px;
        margin-bottom: 5.2083vw;
        background: url('https://fxwebsite.rencaijituan.com/global/home/bg.png') no-repeat center center;
        background-size:100% 100%;
        -webkit-box-shadow: 0 16px 40px rgba(0, 0, 0, 0.04);
        -moz-box-shadow: 0 16px 40px rgba(0, 0, 0, 0.04);
        box-shadow: 0 16px 40px rgba(0, 0, 0, 0.04);
        overflow: hidden;
        padding: 0 2.0833vw;
        position: relative;
        > img {
          display: block;
          // max-width: 100%;
          // max-height: 300px;
          width: 100%;
          margin: 0 auto;
          margin-top: 10px;
          // margin-bottom: 105px;
        }
        .res-title {
          font-size: 1.25vw;
          font-weight: 500;
          &::after {
            content: '';
            position: absolute;
            left: 50%;
            margin-top: 0.5729vw;
            margin-left: -1.25vw;
            width: 10.4167vw;
            height: 1px;
            background-color: rgba(0, 0, 0, 0.1);
          }
        }
        .res-content { 
          margin-top: 1.25vw;
          font-size: 0.8333vw;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.6);
          line-height: 2vw;
        }
        > span {
          position: absolute;
          bottom: 3.3333vw;
          right: 2.0833vw;
          > a {
            // color: #00ADF6;
            color: rgba(0, 0, 0, 0.85);
            font-size: 0.8333vw;
            font-weight: 400;
            > i {
            
              transform: rotateY(180deg) rotate(-90deg);
              margin-left: 9px;
            }
            &:hover {
              color: #00ADF6;
            }
          }
          
          
        }
      }
      .residential {
        // box-sizing: border-box;
        // width: 440px;
        height: 40.7813vw;
        
        > img {
          // display: block;
          // width: 325px;
          // height: 250px;
          // margin: 0 auto;
          // margin-top: 34px;
          margin-bottom: 1.4583vw;
        }
        


      }
      .homecare {
        height: 38.8021vw;
        > img {
          margin-bottom: 1.4583vw;
        }
        .res-title {
          font-size: 1.25vw;
          font-weight: 500;
          &::after {
            content: '';
            position: absolute;
            left: 60%;
            // margin-left: -20px;
            margin-top: 0.5729vw;
            // right: 0;
            width: 7.9167vw;
            height: 1px;
            background-color: rgba(0, 0, 0, 0.1);
          }
        }
      }
      .civil {
        height: 34.8438vw;
        > img {
          margin-bottom: 1.4583vw;
        }
      }

    }
    
    .tips {
      margin-top: 20px;
      height: 12.5vw;
      padding: 0 $my_padding;
      background: url('https://fxwebsite.rencaijituan.com/global/home/bg2.png') no-repeat center center;
      background-size:100% 100%;
      display: flex;
      align-items: center;
      .tips-item {
        // overflow: hidden;
        height: 4.7917vw;
        width: 18.2292vw;
        padding: 0 2.34375vw;
        box-sizing: border-box;
        position: relative;
        &::after {
          content: '';
          position: absolute;
          // left: 60%;
          // margin-left: -20px;
          // margin-top: 11px;
          bottom: 50%;
          margin-bottom: -2.0833vw;
          right: 0;
          width: 1px;
          height: 4.1667vw;
          background-color: rgba(0, 173, 246, 0.7);
        }
        > div {
          height: 100%;
          position: relative;
          > span:nth-child(1) {
            display: inline-block;
            font-size: 3.125vw;
            font-weight: 700;
            color: #00ADF6;
            margin-bottom: 0.8333vw;
          }
          > span:nth-child(2) {
            font-size: 1.4583vw;
            font-weight: 400;
            color: #00ADF6;
            position: absolute;
            margin-left: 5px;
            top: 0;
          }
          > p {
            font-size: 0.8333vw;
            font-weight: 400;
            color: #00ADF6;
            
          }
          > i {
            position: absolute;
            top: 50%;
            margin-top: -1.04167vw;
            right: 0;
            .svg-icon {
              width: 2.0833vw;
              height: 2.0833vw;
            }
          }
        }
      }
      .tips-item:last-child {
        &::after {
          content: '';
          position: none;
          bottom: 50%;
          margin-bottom: -2.0833vw;
          right: 0;
          width: 0;
          height: 4.1667vw;
        }
      }
    }
    .hardware-product {
      padding: 0 $my_padding;
      margin-top: 120px;
      .product-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 64px;
        > span {
          font-size: 36px;
          font-weight: 500;
        }
        > a {
          color: rgba(0, 0, 0, 0.85);
          font-size: 16px;
          font-weight: 400;
          > i {
          
            transform: rotateY(180deg) rotate(-90deg);
            margin-left: 9px;
          }
          &:hover {
            color: #00ADF6;
          }
        }
      }
      .product-content {
        display: flex;
        justify-content: space-between;
        // justify-content: center;
        flex-wrap: wrap;
        
        .product-items {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 2.0833vw;
          box-sizing: border-box;
          background: rgba(0, 173, 246, 0.04);
          width: 35.4167vw;
          // min-width: 528px;
          height: 13.5417vw;
          margin-bottom: 2.0833vw;
          border-radius: 8px;
          >div {
            height: 100%;
            padding: 10px 0;
            max-width: 19.7917vw;
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            > p:first-child {
              font-size: 1.25vw;
              font-weight: 500;
              // margin-bottom: 88px;
            }
            > p:last-child {
              font-size: 0.8333vw;
              font-weight: 400;
              color: rgba(0, 0, 0, 0.6);
              line-height: 1.6667vw;
            }
          }
          >img {
            height: 9.375vw;
            width: 9.375vw;
          }
        }
      }
    }

    .invest-project {
      margin: 80px 0 120px 0;
      padding: 0 $my_padding;
      
      .invest-title {
        display: flex;
        justify-content: space-between;
        // align-items: center;
        margin-bottom: 64px;
        > span:first-child {
          font-size: 36px;
          font-weight: 500;
          margin-right: 64px;
        }
        > span:nth-child(2) {
          display: inline-block;
          flex: 1;
          font-size: 16px;
          font-weight: 400;
          text-align: right;
          border-bottom: 1px solid rgba(0, 0, 0, 0.1);
          color: rgba(0, 0, 0, 0.6);
          line-height: 36px;
        }
        
      }
      .invest-content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        > div:first-child {
          max-width: 720px;
          max-height: 320px;
          margin-right: 20px;
          > img {
            height: 100%;
            width: 100%;
          }
        }
        
        > div:nth-child(2) {
          height: 320px;
          position: relative;
          display: flex;
          align-items: center;
          > p {
            max-width: 580px;
            font-size: 16px;
            font-weight: 400;
            line-height: 38.4px;
            color: rgba(0, 0, 0, 0.6);
          }
          > a {
            position: absolute;
            right: 0;
            bottom: 0;
            color: rgba(0, 0, 0, 0.85);
            font-size: 16px;
            font-weight: 400;
            > i {
            
              transform: rotateY(180deg) rotate(-90deg);
              margin-left: 9px;
            }
            &:hover {
              color: #00ADF6;
            }
          }
        }
      }
    }
  }
  @media (max-width: 1441px) {
      .service-introduce {
        flex-wrap: wrap;
        // justify-content: space-around !important;
      }
      .product-content {
        // justify-content: center !important;
      }
    } /*<=1440以下的设备*/
</style>