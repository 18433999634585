<template>
  <div class="container">
    <ul class="nav">
      <router-link active-class="active" tag="li" to="/residential/rIntroduce/1">评估标准智能化</router-link>
      <router-link active-class="active" tag="li" to="/residential/rIntroduce/2">入住流程电子化</router-link>
      <router-link active-class="active" tag="li" to="/residential/rIntroduce/3">服务质量监管</router-link>
      <router-link active-class="active" tag="li" to="/residential/rIntroduce/4">异常状况智能报警</router-link>
      <router-link active-class="active" tag="li" to="/residential/rIntroduce/5">结算费用自动化</router-link>
    </ul>
    <div class="main">
      <div class="swiper">
        
        <el-carousel :interval="4000" height="39.5833vw" indicator-position="outside" arrow="never" trigger="click"  ref="carousel"
      >
          <el-carousel-item v-for="item in swiperList" :key="item.id">
            <img :src="item.url" alt="">
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="introduce">
        <div class="introduce-item" v-for="item in swiperList" :key="item.id">
          <div class="introduce-title">
            <svg-icon icon-class="jiantou"></svg-icon>
            <span>{{item.title}}</span>
          </div>
          <div class="introduce-content">{{item.content}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Vue from 'vue';
  import { Carousel, CarouselItem } from 'element-ui'
  Vue.use(Carousel);
  Vue.use(CarouselItem);
  export default {
      props: ['id'],
      data() {
        return {
          //轮播图列表
          swiperList: [],
          activeIndex: -1,
        };
      },
      created() {
        this.getSwiperList();
      },
      mounted() {
        /**
       * 重构carousel组件的方法
       * @method handleMouseEnter
       * 重写方法取消默认鼠标移入时取消计时器
       * @method handleIndicatorClick
       * 重写方法当点击指示器时切换下一张重新计时(由于内部实现方式为鼠标移入停止计时，没有持续轮播方法)
       */
        this.$refs.carousel.handleMouseEnter = () => { };
        this.$refs.carousel.handleIndicatorClick = (index) => {
          this.$refs.carousel.setActiveItem(index);
        };

        this.$nextTick(function () {this.handleDomTransition()});
        

      },
      watch: {
        id(newV,oldV) {
          // do something
          // console.log(newV)
          this.handleDomTransition();
          this.getSwiperList();
        } 
      },
      methods: {
        //获取轮播图列表方法
        getSwiperList() {
          // console.log(this.id);
          // this.aid = this.id;
          if(this.id == 1) {
            this.swiperList = [
                {
                  id: 1,
                  url: 'https://fxwebsite.rencaijituan.com/global/residential/ys-img1.png',
                  title: '老人入院评估（初评）',
                  content: '按照评估标准规范，系统设置有 12 套现成评估表，评估员根据系统评估表标准对老人表现出来的现状进行评估勾选，系统自动化计算，杜绝人为计算错误，得出老人当前的评估分数和评估结论。'
                },
                {
                  id: 2,
                  url: 'https://fxwebsite.rencaijituan.com/global/residential/ys-img2.png',
                  title: '老人周期评估（复评）',
                  content: '在院老人需要周期性进行身体状况评估，系统根据周期自动提醒评估员，避免评估员忘记复评；以评估数据结论为依据，对老人进行合理的服务调整。'
                },
                {
                  id: 3,
                  url: 'https://fxwebsite.rencaijituan.com/global/residential/ys-img3.png',
                  title: '机构星级评定',
                  content: '评估系统依照省星级评定标准进行设计研发，通过智能平板协助星评评估专家进行智能化评定；为评估数据查证提供支撑。'
                }
              ]
          }else if(this.id == 2) {
            this.swiperList = [
              {
                id: 4,
                url: 'https://fxwebsite.rencaijituan.com/global/residential/ys-img4.png',
                title: '无纸化入住',
                content: '系统一键办理入住，信息电子化，记录查询高效快捷；优化传统纸质信息存档，节省办公用品成本。'
              },
              {
                id: 5,
                url: 'https://fxwebsite.rencaijituan.com/global/residential/ys-img5.png',
                title: '电子签名',
                content: '入住合同、入住须知，入住评估，入住家属同意书等文件老人家属通过电子化签名确认，便捷、永久保存，随时可导出查证。'
              },
              {
                id: 6,
                url: 'https://fxwebsite.rencaijituan.com/global/residential/ys-img6.png',
                title: '老人档案',
                content: '老人基本资料、家属资料、病史资料、院内事项完全信息化留档，数据一键记录和查询，高效便捷，永久保存，避免信息档案遗失或因存档时间长久腐烂等情况，建立老人档案动态数据库。'
              }
            ]
          }else if(this.id == 3) {
            this.swiperList = [
              {
                id: 7,
                url: 'https://fxwebsite.rencaijituan.com/global/residential/ys-img7.png',
                title: '事故处理及跟进监管',
                content: '系统对事故细节全面记录，事故信息实时连接护士站显示屏进行提醒跟进，完全实现电子化，提高事故处理监管，工作人员责任落实到位。'
              },
              {
                id: 8,
                url: 'https://fxwebsite.rencaijituan.com/global/residential/ys-img8.png',
                title: '日常照护监管',
                content: '老人日常照护项目可在系统一键开启，照护信息数据实时连接护士站显示屏进行提醒跟进； 并通过老人房前人脸识别系统与智慧院舍系统数据连接，实时查看监管护士/护工的巡房看护记录，对老人突发状况起着重大保障作用。'
              },
              {
                id: 9,
                url: 'https://fxwebsite.rencaijituan.com/global/residential/ys-img9.png',
                title: '护理警报处理监管',
                content: '系统通过引入物联网硬件设备，针对不同身体素质的老人使用一定的健康安全监测设备，设备数据达到异常值时，通过系统、员工手表等警报通知护士/护工跟进处理；通过系统、设备、 物联网等结合对服务进行全面监管，全面保障老人安全健康。'
              }
            ]
          }else if(this.id == 4) {
            this.swiperList = [
              {
                id: 10,
                url: 'https://fxwebsite.rencaijituan.com/global/residential/ys-img10.png',
                title: '毫秒级延迟',
                content: '通过物联网硬件设备 (智能纸尿裤、血糖仪、血压仪等) 监测，数据实时上传系统，达到异常值智能触发警报，实现毫秒级延迟速度。'
              },
              {
                id: 11,
                url: 'https://fxwebsite.rencaijituan.com/global/residential/ys-img11.png',
                title: '无感接触，智能感知触发警报',
                content: '物联网硬件设备通过无感接触，自动化上传数据，根据系统设置的警报红线值，自动触发本地警报、声音警报、远程警报等。'
              },
              {
                id: 12,
                url: 'https://fxwebsite.rencaijituan.com/global/residential/ys-img12.png',
                title: '多样化智能设备警报',
                content: '系统通过引入物联网硬件设备，针对不同身体素质的院舍老人使用一定的健康安全监测设备，针对不同设备警报做出应急处理。'
              }
            ]
          }else if(this.id == 5) {
            this.swiperList = [
              {
                id: 13,
                url: 'https://fxwebsite.rencaijituan.com/global/residential/ys-img13.png',
                title: '全自动生成结算单',
                content: '每月根据日常护理服务、物资、水电等费用的信息化记录，系统自动生成费用结算单，优化了传统人工结算，提高效率。'
              },
              {
                id: 14,
                url: 'https://fxwebsite.rencaijituan.com/global/residential/ys-img14.png',
                title: '避免费用少收漏收',
                content: '系统自动根据服务费、物资费、减免费计算，得出结算总费用，实现全自动化，避免人工计算误差导致的少收漏收。'
              },
              {
                id: 15,
                url: 'https://fxwebsite.rencaijituan.com/global/residential/ys-img15.png',
                title: '客户自助缴费',
                content: '链接一键发送费用清单，家属对费用清单一目了然，在线扫码缴费，足不出门，方便快捷。'
              }
            ]
          }
          
          // console.log(this.swiperList);
        },
        delHandleMouseEnter() {
            this.$refs.carousel.handleMouseEnter = () => {};
        },

        /* css3的transition效果实现的进度条在首次进入页面时不生效，通过异步方法解决 */
        handleDomTransition() {
          let el = document.getElementsByClassName('el-carousel__indicator')[0];
          
          if(el.classList.contains('is-active') == true) {
            el.classList.remove('is-active');
            setTimeout(() => {
              el.classList.add('is-active');
            }, 0.1)
            
          }
          
          
        }
      },
  }
</script>

<style lang="scss" scoped>
  $my_padding: percentage(260/1920);
  .container {
    // padding: 0 $my_padding;
    margin-top: 40px;
    .nav {
      padding: 0 $my_padding;
      display: flex;
      justify-content: space-around;
      > li {
        padding: 0.4167vw 0.8333vw;
        font-size: 0.8333vw;
        font-weight: 500;
        color: rgba(0, 0, 0, 0.85);
        border-radius: 1.0417vw;
        cursor: pointer;
        &:hover {
          color: #00ADF6;
        }
      }
      .active {
        background: #00ADF6;
        color: #FFFFFF;
        &:hover {
          color: #FFFFFF;
        }
      }
    }
    .main {
      margin-top: 50px;
      .swiper {
        text-align: center;
        ::v-deep .el-carousel {
          .el-carousel__item {
            /* display: flex !important;
            justify-content: center;
            align-items: flex-start; */
            overflow: hidden;
            > img {
              height: 100%;
            }
          }
          .el-carousel__indicators {
            padding: 0 $my_padding;
            display: flex !important;;
            justify-content: space-between;
            .el-carousel__indicator {
              display: block;
              height: 4px;
              .el-carousel__button{
                height: inherit;
                width: 21.5104vw;
                position: relative;
                overflow: hidden;
                &::after {
                  content: "";
                  display: block;
                  position: absolute;
                  width: 100%;
                  height:100%;
                  top:0;
                  left: -100%;
                  background: #00ADF6;
                }
              }
              &.is-active {
                .el-carousel__button::after{
                  transition: left 4s linear;
                  left: 0;
                }
              } 
            }
          }
        }
      }
      .introduce {
        padding: 0 $my_padding;
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
        margin-bottom: 120px;
        .introduce-item {
          width: 21.5104vw;
          .introduce-title {
            display: flex;
            align-items: center;
            font-size: 1.25vw;
            font-weight: 500;
            line-height: 1.875vw;
            height: 1.875vw;
            .svg-icon {
              height: 1.875vw;
              width: 1.25vw;
              margin-right: 0.8333vw;
            }
          }
          .introduce-content {
            font-size: 0.7292vw;
            font-weight: 400;
            line-height: 1.4583vw;
            color: rgba(0, 0, 0, 0.6);
            margin-top: 0.8333vw;
          }
        }
      } 
    }
  }
</style>