<template>
  <div class="banner">
    <img :src="require('@/assets' + bannerList.src)" alt="">
    <div class="banner-tips" :style="{height: bannerList.height}">
      <div class="tips-item" v-for="(item, index) in bannerList.list" :key="index">
        <svg-icon :icon-class="item.icon"></svg-icon>
        <div>
          <p>{{item.title}}</p>
          <p>{{item.content}}</p>
        </div>
          
      </div>
      
    </div>
  </div>
</template>
<script>
  export default {
    props: {
        bannerList: {
            type: Object,
            required: true
        }
    },
  }
</script>

<style lang="scss" scoped>
  .banner {
    position: relative;
    > img {
        width: 100%;
    }
    .banner-tips {
      width: 72.9167vw;
      // height: 13.5417vw;
      background-color: #ffffff;
      box-sizing: border-box;
      border-radius: 8px;
      padding: 2.0833vw 3.3333vw;
      display: flex;
      justify-content: space-between;
      position: absolute;
      top: 33.3333vw;
      left: 13.5417vw;
      -webkit-box-shadow: 0 16px 40px rgba(0, 0, 0, 0.04);
      -moz-box-shadow: 0 16px 40px rgba(0, 0, 0, 0.04);
      box-shadow: 0 16px 40px rgba(0, 0, 0, 0.04);
      .tips-item {
        display: flex;
        .svg-icon {
          width: 4.1667vw;
          height: 4.1667vw;
          margin-right: 0.8333vw;
        }
        > div {
          > p {
            font-size: 1.25vw;
            color: rgba(0, 0, 0, 0.85);
            &:last-child {
              font-size: 0.8333vw;
              line-height: 1.6667vw;
              font-weight: 400;
              color: rgba(0, 0, 0, 0.6);
              width: 14.5833vw;
              margin-top: 1.25vw;
            }
          }
        }
        
      }
    }
  }
</style>