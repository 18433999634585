<template>
	<div class="container">
    <div class="banner">
      <img src="https://fxwebsite.rencaijituan.com/global/invest/banner.jpg" alt="">
      <div class="banner-content">
        <p>繁星医养投资项目介绍</p>
        <p>广东繁星医养集团根据符合投资条件、有意向在养老机构自身地方投资医院的合作伙伴提供资金、运营等一站式服务的综合投资项目，最终打造机构为医养一体综合机构体系，达到双赢一种合作模式。(项目同样适用于医院投资养老机构)</p>
        <span>携手合作</span>
      </div>
    </div>
    <div class="explain">
      <div class="explain-care">
        <div class="title">医养</div>
        <div class="content">
          <svg-icon icon-class="older"></svg-icon>
          <p>随着我国现阶段老龄化社会“未富先老”矛盾重重，由于一些“老年病”的常发、易发和突发性，患病、失能、半失能老人的治疗和看护问题困扰着千家万户。而现状却是——医疗机构和养老机构互相独立自成系统，养老院不方便就医，医院里又不能养老。医疗和养老的分离，造成了老年人就医的极大困难。而医养结合就是针对缓解这一问题提出的新型模式，方便了老年的生活。</p>
        </div>
      </div>
      <div class="explain-combine">
        <div class="title">结合</div>
        <div class="content">
          <svg-icon icon-class="YL"></svg-icon>
          <p>医养结合可以有效提高老年人的健康水平、改善老年人的生活质量，是满足亿万老年人健康养老需求的重大举措。中国现有养老产品的一个共同问题是缺乏优质的医疗服务。</p>
        </div>
      </div>
      <div class="explain-benefit">
        <div class="title">对社会益处</div>
        <div class="content">
          <svg-icon icon-class="HZ"></svg-icon>
          <p>如何通过提高医疗服务能力让老人实现“老有尊严”是中国养老产业接下来的重点。因此，医养结合是中国养老产业未来一段时间的重中之重！</p>
        </div>
      </div>
    </div>
    <div class="benefit-introduction">
      <div class="benefit-content">
        <div class="benefit-l">
          <div class="benefit-title">
            医养结合<br />对养老机构益处
          </div>
          <p><span>01—</span><span>提升机构在当地影响力和知名度</span></p>
          <p><span>02—</span><span>提升机构整体质量和服务能力</span></p>
          <p><span>03—</span><span>提升机构营收能力和增加收入</span></p>
          <p><span>04—</span><span>增加院舍因病患而造成应急处理的能力</span></p>
        </div>
        <div class="benefit-r">
          <p><span>05—</span><span>减少老人因病转院造成床位流失</span></p>
          <p><span>06—</span><span>减少老人因病而造成焦虑</span></p>
          <p><span>07—</span><span>减轻家属因病转院的烦恼</span></p>
          <p><span>08—</span><span>更加容易吸引老年人入住</span></p>
          <p><span>09—</span><span>跟随国家政策实现可持续发展</span></p>
        </div>
      </div>
      

    </div>
    <div class="problem">
      <div class="problem-l">
        <img src="https://fxwebsite.rencaijituan.com/global/invest/problem.png" alt="">
        <img src="https://fxwebsite.rencaijituan.com/global/invest/bottom.png" alt="">
      </div>
      <div class="problem-r">
        <p>繁星医养集团拥有成熟<br />自主开发医养管理系统 </p>
        <p>无论针对医院还是养老院都能够在信息化、智能化<br /> (无感化) 方面得到很好落地，对于医养合作伙伴我们全力支持！</p>
      </div>
    </div>
    <div class="condition">
      <div class="condition-l">
        <div class="condition-title">养老机构与繁星<br/>医养合作基础条件</div>
        <div class="condition-content">
          <p>任何合作都是建立在双赢前提，繁星医养对于机构合作有严格要求，目的是减小投资风险，创造最大利润 ，共同打造当地一流医养合作项目，树立标杆，为社会做出贡献。</p>
          <p>繁星医养集团杜绝任何不法投资和合作，按照《医疗机构管理条例》、《定点医疗机构基本医疗保险服务协议》、《传染病防治法》、《医疗废物处理条例》等法律、法规，依法、依规经营医疗机构。（目前以一甲医院投资为主）</p>
        </div>
      </div>
      <div class="condition-r">
        <div class="items">
          <span>50</span><span>+</span>
          <p>能够独立提供楼层<br/>至少 50 个床位给医院部</p>
          
        </div>
        <div class="items">
          <span>3000</span><span>+</span>
          <p>经营场地不少于 3000 方</p>
          
        </div>
        <div class="items">
          <span>150</span><span>+</span>
          <p>床位数 150 个或以上<br/>入住老人超过 100 人以上优先考虑合作</p>
          
        </div>
        <div class="items">
          <span><svg-icon icon-class="xf"></svg-icon></span>
          <p>消防环保配置已经完善</p>
          
        </div>
      </div>
    </div>
    <div class="tips">
      <!-- <img src="../assets/global/invest/part7.jpg" alt=""> -->
      <div class="tips-title">
        合作流程
      </div>
      <div class="tips-content">
        <div class="tips-item">
          <div class="number">01</div>
          <div class="tips-item-title">符合条件机构<br/>(有意向合作)</div>
          
        </div>
        <div class="tips-item">
          <div class="number">02</div>
          <div class="tips-item-title">参观繁星<br />医养项目基地</div>
        </div>
        <div class="tips-item">
          <div class="number">03</div>
          <div class="tips-item-title">机构<br />实地考察</div>
        </div>
        <div class="tips-item">
          <div class="number">04</div>
          <div class="tips-item-title">商讨<br />合作细节</div>
        </div>
        <div class="tips-item">
          <div class="number">05</div>
          <div class="tips-item-title">确立<br />合作关系</div>
        </div>
        <div class="tips-item">
          <div class="number">06</div>
          <div class="tips-item-title">签订<br />合作协议</div>
        </div>
        <div class="tips-item">
          <div class="number">07</div>
          <div class="tips-item-title">项目<br />对接</div>
        </div>
      </div>
      
    </div>
    <div class="cooperation-mode">
      <div class="cooperation-l">
        <div class="cooperation-title">合作方式</div>
        <div class="cooperation-item">
          <span>01</span>
          <p>共同持股<br/>(可选择资金方式或其他方式入股)</p>

        </div>
        <div class="cooperation-item">
          <span>02</span>
          <p>其他合作方式<br/>(待实际考察项目再定)</p>

        </div>
        <p class="cooperation-content">
          通过繁星医养项目合作，通过线上线下无缝对接社区老人健康档案搭建、 送药上门、 家政、长者饭堂、居家智能化设备 改造等，打通和解决养老机构底层流量问题！
        </p>
      </div>
      <div class="cooperation-r">
        <img src="https://fxwebsite.rencaijituan.com/global/invest/flowchart.png" alt="">
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    data() {
      return {

      };
    },
    
    
    
    methods: {
      
    },
    
  }
</script>

<style lang="scss" scoped>
	$my_padding: percentage(260/1920);
	.container {
    margin-top: 100px;
    .banner {
      position: relative;
      > img {
        width: 100%;
        height: 100%;
      }
      .banner-content {
        width: 37.5vw;
        height:16.9792vw;
        background: rgba(255, 255, 255, 0.8);
        border-radius: 0 8.4896vw 0 0;
        position: absolute;
        left: $my_padding;
        bottom: 0;
        padding: 3.3333vw 3.3333vw 0 3.3333vw;
        box-sizing: border-box;
        > p:first-child {
          font-size: 1.6667vw;
          font-weight: 500;
          color: rgba(0, 0, 0, 0.85);
          margin-bottom: 1.25vw;
        }
        > p:nth-child(2) {
          font-size: 0.8333vw;
          font-weight: 400;
          line-height: 1.6667vw;
          color: rgba(0, 0, 0, 0.6);

        }
        > span {
          color: rgba(0, 0, 0, 0.85);
          font-size: 0.8333vw;
          font-weight: 400;
          position: absolute;
          bottom: 2.5vw;
          right: 3.3333vw;
          &::before {
            content: '';
            height: 1px;
            width: 40%;
            position: absolute;
            top: 50%;
            left: -50%;
            background-color: rgba(0, 0, 0, 0.85);
            
          }
        }
      }
    }
    .explain {
      padding: 0 percentage(260/1920);
      display: flex;
      justify-content: space-between;
      .explain-care {
        height: 36.9792vw;
        border-left: 1px solid rgba(0, 173, 246, 0.1);
        padding: 4.1667vw 0;
        box-sizing: border-box;
        position: relative;
        .title {
          font-size: 1.6667vw;
          font-weight: 500;
          color: rgba(0, 0, 0, 0.85);
          padding-left: 1.4063vw;
          margin-bottom: 3.3333vw;
          &::before {
            content: '';
            position: absolute;
            width: 4px;
            height: 1.6667vw;
            background-color: #00ADF6;
            left: 0;
          }
        }
        .content {
          box-sizing: border-box;
          // max-width: 480px;
          // height: 440px;
          // min-width: 400px;
          width: 25vw;
          height: 22.9167vw;
          background: rgba(0, 173, 246, 0.04);
          border-radius: 0 11.4583vw 0 0;
          box-shadow: 0 8px 24px rgba(0, 0, 0, 0.04);
          padding: 2.0833vw 2.0833vw 0 2.0833vw;
          > .svg-icon {
            height: 2.5vw;
            width: 2.5vw;
            margin-bottom: 3.3333vw;
          }
          > p {
            font-size: 0.8333vw;
            font-weight: 400;
            line-height: 1.6667vw;
          }
        }
      }

      .explain-combine {
        height: 36.9792vw;
        border-left: 1px solid rgba(0, 173, 246, 0.1);
        padding: 6.25vw 0 4.1667vw 0;
        box-sizing: border-box;
        position: relative;
        .title {
          font-size: 1.6667vw;
          font-weight: 500;
          color: rgba(0, 0, 0, 0.85);
          padding-left: 1.4063vw;
          margin-bottom: 7.1875vw;
          &::before {
            content: '';
            position: absolute;
            width: 4px;
            height: 1.6667vw;
            background-color: #00ADF6;
            left: 0;
          }
        }
        .content {
          box-sizing: border-box;
          // max-width: 420px;
          // height: 326px;
          // min-width: 400px;
          width: 21.875vw;
          height: 16.9792vw;
          background: rgba(0, 173, 246, 0.04);
          border-radius: 0 8.4896vw 0 0;
          box-shadow: 0 8px 24px rgba(0, 0, 0, 0.04);
          padding: 2.0833vw 2.0833vw 0 2.0833vw;
          > .svg-icon {
            height: 2.5vw;
            width: 2.5vw;
            margin-bottom: 3.3333vw;
          }
          > p {
            font-size: 0.8333vw;
            font-weight: 400;
            line-height: 1.6667vw;
          }
        }
      }
      .explain-benefit {
        height: 36.9792vw;
        border-left: 1px solid rgba(0, 173, 246, 0.1);
        padding: 8.3333vw 0 4.1667vw 0;
        box-sizing: border-box;
        position: relative;
        .title {
          font-size: 1.6667vw;
          font-weight: 500;
          color: rgba(0, 0, 0, 0.85);
          padding-left: 1.4063vw;
          margin-bottom: 7.1875vw;
          &::before {
            content: '';
            position: absolute;
            width: 4px;
            height: 1.6667vw;
            background-color: #00ADF6;
            left: 0;
          }
        }
        .content {
          box-sizing: border-box;
          // max-width: 420px;
          // height: 262px;
          // min-width: 400px;
          width: 21.875vw;
          height: 13.6458vw;
          background: rgba(0, 173, 246, 0.04);
          border-radius: 0 6.8229vw 0 0;
          box-shadow: 0 8px 24px rgba(0, 0, 0, 0.04);
          padding: 2.0833vw 2.0833vw 0 2.0833vw;
          > .svg-icon {
            height: 2.5vw;
            width: 2.5vw;
            margin-bottom: 3.3333vw;
          }
          > p {
            font-size: 0.8333vw;
            font-weight: 400;
            line-height: 1.6667vw;
          }
        }
      }
    }
    .benefit-introduction {
      width: 100%;
      height: 28.125vw;
      padding: 0 $my_padding;
      box-sizing: border-box;
      background: url('https://fxwebsite.rencaijituan.com/global/invest/bg-img1.png') no-repeat center center;
      background-size: 100% 100%;
      overflow: hidden;
      .benefit-content {
        width: 54%;
        display: flex;
        justify-content: space-between;
        margin-top: 3.3333vw;
        .benefit-l {
          .benefit-title {
            font-size: 1.6667vw;
            font-weight: 500;
            line-height: 2.4135vw;
            color: #FFFFFF;
            padding-left: 1.4583vw;
            box-sizing: border-box;
            position: relative;
            margin-bottom: 3.0469vw;
            &::before {
              content: '';
              width: 4px;
              height: 100%;
              background-color: #FFFFFF;
              left: 0;
              position: absolute;
            }
          }
          > p {
            margin-bottom: 2.34375vw;
            > span {
              color: #FFFFFF;
              font-weight: 500;
              &:nth-child(1) {
                font-size: 1.25vw;
                margin-right: 0.8333vw;
              }
              &:nth-child(2) {
                font-size: 0.9375vw;
              }
            }
          }
        }
        .benefit-r {
          padding-top: 4.27083vw;
          box-sizing: border-box;
          > p {
            margin-bottom: 2.34375vw;
            > span {
              color: #FFFFFF;
              font-weight: 500;
              &:nth-child(1) {
                font-size: 1.25vw;
                margin-right: 0.8333vw;
              }
              &:nth-child(2) {
                font-size: 0.9375vw;
              }
            }
          }
        }
      }
      /* > img {
        width: 100%;
      } */
    }
    .problem {
      height: 7.8125vw;
      padding: 0 $my_padding;
      margin: 120px 0;
      display: flex;
      justify-content: space-between;
      box-sizing: border-box;
      .problem-l {
        // width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        > img {
          width: 100%;
          
        }
      }
      .problem-r {
        // max-width: 447px;
        width: 23.28125vw;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: space-between;
        > p:first-child {
          // max-width: 160px;
          text-align: right;
          
        }
        > p {
          text-align: right;
          font-size: 0.8333vw;
          font-weight: 400;
          line-height: 1.6667vw;
          color: rgba(0, 0, 0, 0.85);
        }
      }
    }
    .condition {
      padding: 0 $my_padding;
      display: flex;
      > div {
        width: 50%;
      }
      .condition-l {
        box-sizing: border-box;
        padding: 0 6.4583vw 0 1.4583vw;
        position: relative;
        border-right: 1px solid rgba(0, 173, 246, 0.1);
        .condition-title {
          font-size: 1.6667vw;
          font-weight: 500;
          line-height: 2.4135vw;
          margin-bottom: 2.0833vw;
          &::before {
            content: '';
            position: absolute;
            width: 4px;
            height: 4.5833vw;
            background-color: #00ADF6;
            left: 0;
            top: 4px;
          }
        }
        .condition-content {
          > p {
            font-size: 0.8333vw;
            font-weight: 400;
            line-height: 1.6667vw;
            color: rgba(0, 0, 0, 0.6);
            &:first-child {
              margin-bottom: 1.25vw;
            }
          }
        }
      }
      .condition-r {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding-left: 6.4583vw;
        .items {
          position: relative;
          width: 50%;
          > span:first-child {
            display: inline-block;
            font-size: 3.125vw;
            font-weight: 700;
            color: #00ADF6;
            margin-bottom: 0.8333vw;
          }
          > span:nth-child(2) {
            font-size: 1.4583vw;
            font-weight: 400;
            color: #00ADF6;
            position: absolute;
            margin-left: 5px;
            top: 0;
          }
          > p {
            font-size: 0.8333vw;
            font-weight: 400;
            line-height: 1.6667vw;
            color: rgba(0, 0, 0, 0.6);
          }
          &:last-child {
            > span:first-child {
            font-size: 4.5833vw;
            
          }
          }
        }

      }
    }
    .tips {
      padding: 3.3333vw $my_padding;
      box-sizing: border-box;
      background: url('https://fxwebsite.rencaijituan.com/global/invest/bg-img2.png') no-repeat center center;
      background-size:100% 100%;
      margin-top: 120px;
      width: 100%;
      height: 19.2708vw;
      .tips-title {
        font-size: 1.6667vw;
        font-weight: 500;
        line-height: 2.4135vw;
        color: #00ADF6;
        padding-left: 1.4583vw;
        box-sizing: border-box;
        position: relative;
        margin-bottom: 2.0833vw;
        &::before {
          content: '';
          width: 4px;
          height: 1.6667vw;
          background-color: #00ADF6;
          left: 0;
          top: 50%;
          margin-top: -0.8333vw;
          position: absolute;
        }
      }
      .tips-content {
        display: flex;
        justify-content: space-between;
        .tips-item {
          .number {
            font-weight: 500;
            font-size: 1.875vw;
            color: #00ADF6;
            margin-bottom: 1.6667vw;
            position: relative;
            &::after {
              content: '';
              width: 0.8333vw;
              height: 1px;
              background-color: #00ADF6;
              position: absolute;
              bottom: -0.8333vw;
              left: 0;
            }
          }
          .tips-item-title {
            font-size: 0.9375vw;
            font-weight: 500;
            color: #00ADF6;
            line-height: 1.875vw;
          }
        }
      }
    }
    .cooperation-mode {
      padding: 0 $my_padding;
      display: flex;
      justify-content: space-between;
      .cooperation-l {
        position: relative;
        padding: 120px 4.1% 0 0;
        box-sizing: border-box;
        height: 50.1042vw;
        border-right: 1px solid rgba(0, 173, 246, 0.1);
        .cooperation-title {
          padding-left: 1.4583vw;
          font-size: 1.6667vw;
          font-weight: 500;
          margin-bottom: 3.3333vw;
          color: rgba(0, 0, 0, 0.85);
          &::before {
            content: '';
            position: absolute;
            width: 4px;
            height: 1.6667vw;
            background-color: #00ADF6;
            left: 0;
          }
        }
        .cooperation-item {
          height: 8.3333vw;
          width: 20.8333vw;
          background: url('https://fxwebsite.rencaijituan.com/global/invest/01.png') no-repeat right bottom;
          box-shadow: 0 8px 24px rgba(0, 0, 0, 0.04);
          overflow: hidden;
          padding-left: 2.0833vw;
          box-sizing: border-box;
          border-radius: 8px;
          // background-size:100% 100%;
          > span {
            display: block;
            width: 1.25vw;
            height: 1.25vw;
            border-radius: 50%;
            background-color: #00ADF6;
            font-size: 0.7292vw;
            font-weight: 500;
            line-height: 1.25vw;
            text-align: center;
            color: #FFFFFF;
            // margin-left: 40px;
            margin-top: 1.25vw;
          }
          > p {
            font-size: 1.0417vw;
            font-weight: 500;
            line-height: 1.875vw;
            color: #00ADF6;
            margin-top: 0.8333vw;
          }
          &:nth-child(3) {
            background: url('https://fxwebsite.rencaijituan.com/global/invest/02.png') no-repeat right bottom;
            margin-top: 2.2917vw;
          }
        }
        .cooperation-content {
          font-size: 0.8333vw;
          font-weight: 400;
          line-height: 1.6667vw;
          color: rgba(0, 0, 0, 0.6);
          max-width: 406px;
          width: 21.1458vw;
          margin: 84px 0 0 1.4583vw;

        }
      }
      .cooperation-r {
        // max-width: 851px;
        // min-width: 600px;
        width: 44.3229vw;
        display: flex;
        align-items: center;
        > img {
          width: 100%;
        }
      }
    }
	}
  @media (max-width: 1601px) {
    /* .explain {
      flex-wrap: wrap;
      justify-content: space-around !important;
    } */
  } /*<=1600以下的设备*/
  @media (max-width: 1441px) {
    /* .banner-content {
      padding: 2% 2% 0 2% !important;
      > p:first-child {
          font-size: 28px !important;
        }
        
    } */
  } /*<=1440以下的设备*/

</style>