<template>
  <div class="news-container">
    <!-- <div class="banner">
      <img src="https://fxwebsite.rencaijituan.com/global/news/banner.jpg" alt="banner">
    </div> -->
    <div class="nav">
      <el-menu :default-active="activeIndex" mode="horizontal" text-color="#000000D9" active-text-color="#00ADF6" router>
        <el-menu-item index="/news/newsList/1">机构动态</el-menu-item>
        <el-menu-item index="/news/newsList/4">公益资讯</el-menu-item>
		<el-menu-item index="/news/newsList/2">时事资讯</el-menu-item>
		<el-menu-item index="/news/newsList/3">国家政策</el-menu-item>
      </el-menu>
    </div>
    <div class="news-main">
      <div v-for="(item, index) in newsList" :key="index" class="news-item">
        <img :src="item.newsLogo" alt="" @click="toDetail(item.id)">
        <div class="news-content">
          <p @click="toDetail(item.id)">{{item.newsTitle}}<span>{{item.newsPublishDate ? item.newsPublishDate.split(' ')[0] : ''}}</span></p>
          <div @click="toDetail(item.id)">{{item.newsSummary}}</div>
          <span @click="toDetail(item.id)">了解更多<i class="el-icon-sort-up"></i></span>
        </div>
        

      </div>
    </div>
  </div>
</template>
<script>
  
  export default {
    props: ['id'],
    data() {
      return {
        newsList: [],
      };
    },
    created() {
      this.getNewsList();
      
    },
    watch: {
      id(newV,oldV) {
        this.newsList = [];
        this.getNewsList();
        // console.log(this.$route);
      } 
    },
    computed: {
      activeIndex() {
        const { params } = this.$route;
        const { id } = params;
        switch(id) {
          case '1':
            return '/news/newsList/1';
          case '2': 
            return '/news/newsList/2';
          case '3':
            return '/news/newsList/3';
          case '4':
			return '/news/newsList/4';
        }
      },
    
    },
    methods: {
      async getNewsList() {
        // console.log(this.id);
        let result = await this.$http.get({
            url: '/client/web/news/list',
            params: {
              'newsType': this.id
            }
        })
        
        let { rows } = result;
        // console.log(rows);
        this.newsList = [...rows];
        // console.log(this.newsList);
      },
      toDetail(id) {
        this.$router.push({ name:'newsDetail', params: { id: id}});

      }
    },
  }
</script>
<style lang="scss" scoped>
  $my_padding: percentage(260/1920);
  .news-container {
    margin-bottom: 120px;
    .nav {
       margin-top: 80px;
       padding: 0 $my_padding;
      //  background: red;
      .el-menu {
        // padding: 0 10vw;
        /* display: flex;
        justify-content: space-between; */
        // display: block;
        .el-menu-item {
          display: block;
          position: relative;
          font-size: 1.25vw;
          font-weight: 500;
          font-style: normal;
          padding: 0;
          margin-right: 4.1667vw;
          color: rgba(0, 0, 0, 0.85);
           &.is-active {
            border-bottom: rgba(0, 0, 0, 0) !important;
            // font-weight: 500;
            &::after {
              content: '';
              height: 4px;
              width: 3.3333vw;
              background-color: #00ADF6;
              position: absolute;
              bottom: 0;
              left: 50%;
              margin-left: -1.6667vw;
            }
          }
        }
      }
    }
    .news-main {
      margin-top: 50px;
      padding: 0 $my_padding;
      .news-item {
        display: flex;
        justify-content: space-between;
        margin-bottom: 40px;
        > img {
          width: 20.8333vw;
          height: 13.9583vw;
          background: red;
          border-radius: 8px;
          cursor: pointer;
        }
        .news-content {
          width: 50vw;
          position: relative;
          > p {
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 1.25vw;
            font-weight: 400;
            margin: 1.25vw 0 0.8333vw 0;
            color: rgba(0, 0, 0, 0.85);
            cursor: pointer;
            > span {
              font-size: 0.8333vw;
              font-weight: 400;
              
            }
          }
          > div {
            font-size: 0.8333vw;
            font-weight: 400;
            line-height: 1.6667vw;
            color: rgba(0, 0, 0, 0.6);
            cursor: pointer;
          }
          > span {
            position: absolute;
            left: 0;
            bottom: 1.4583vw;
            color: rgba(0, 0, 0, 0.85);
            font-size: 16px;
            font-weight: 400;
            cursor: pointer;
            > i {
            
              transform: rotateY(180deg) rotate(-90deg);
              margin-left: 9px;
            }
            &:hover {
              color: #00ADF6;
            }
          }
        }
      }
    }
  }
</style>
  