<template>
    <div class="container">
      <!-- 轮播图 -->
      <swiper :src="src" />
      <div class="software">
        <div class="erp">
          <div class="erp-title">软件ERP服务</div>
          <div class="erp-item">
            <p>智领企业 ERP</p>
            <p>企业管理智能化，未来发展全由你掌控；企业 ERP 系统建立在信息技术基础上，以系统化的管理思想，为企业决策层及员工提供决策运行手段的管理平台，包括客户管理、产品管理、项目管理、合同管理、售后管理、采购管理、仓库管理、生产管理、财务管理、人力资源、办公模块等。人才集团助您优化企业，提高效能，不管大小企业，智能化管理随时随地按你所需，一切由定制功能开始。</p>
          </div>
          <div class="erp-item">
            <p>智领优选企业供应链平台</p>
            <p>①  通过智领 ERP 系统，实现企业全流程智能化管理，大大降低采购成本，提高开拓业务的便利性，使采购资源更宽广，更优越。<br />②  善用智能科技，整合供需生态链，提升企业核心竞争力，聚焦业务拓展，让供应链平台助你企业寻找合作伙伴。</p>
          </div>
        </div>
        <div class="vip">
          <div>
            <div class="vip-title">会员商城</div>
            <p>智领优选会员商城</p>
            <p>①  商城自有高端会员（高层次人才、协会商会会员、企业或政府补贴会员等）基础，利于展示企业形象，形成产供销一体化，打造自有品牌，树立品牌高端化，利于企业长远发展。 <br />②  精选优质商品与服务，打造不一样的优质电商平台，实现优质客户共享。<br />③  集合优质商户，会员制分销，打造“好人好物”会员制高端商城，塑造企业形象，长远发展。</p>
          </div>
          
        </div>
        <div class="security">
          <div class="security-title">企业数据安全服务</div>
          <div class="security-content">
            <div class="security-item">
              <svg-icon icon-class="data"></svg-icon>
              <p>数据管理</p>
              <p>集中存取企业所有文件<br />权限管理灵活可控</p>
            </div>
            <div class="security-item">
              <svg-icon icon-class="copy"></svg-icon>
              <p>数据备份和容灾保护</p>
              <p>融合备份、快照、容灾等解决方案为<br />企业，打造坚实的数据保护矩阵</p>
            </div>
            <div class="security-item">
              <svg-icon icon-class="computer"></svg-icon>
              <p>电脑资料备份</p>
              <p>集中计算机备份</p>
            </div>
            <div class="security-item">
              <svg-icon icon-class="tuozhan"></svg-icon>
              <p>高扩展性</p>
              <p>实时会话、管理IP摄像<br />机、虚拟机、邮件<br />中心、多媒体服务器</p>
            </div>
            <div class="security-item">
              <svg-icon icon-class="tb"></svg-icon>
              <p>跨办公室同步和共享</p>
              <p>高效的多站点文件交换：促进本地访问、类似云端体验<br />文件共享和协作：随时随地访问文件、安全的文件共享、文件协作平台<br />可减少维护工作和成本</p>
            </div>
          </div>
        </div>

      </div>
      <div class="human-resources">
        <div class="resources-l">
          <div class="resources-title">
            <p>人力资源服务</p>
          </div>
          <div class="resources-item">
            <p>01.智领会员系统</p>
            <p>一卡通系统<br />服务对象<br />会员管理</p>
          </div>
          <div class="resources-item">
            <p>02.智领人资服务</p>
            <p>构建新型的企业关系<br />实现人才与企业的良性互动<br />共同成长</p>
          </div>
        </div>
        <div class="resources-r">
          <p>03.智领绩效管理平台</p>
          <p>包括培训系统<br />绩效考核系统<br />数据量化管理系统</p>
        </div>
      </div>
      <div class="affairs">
        <div class="affairs-item">
          <div>
            <p>财务服务</p>
            <p>为企业、商场、市场、园区等构建便利低费率收银及支付系统；与企业客户管理、会员系统、营销系统全面打通，不仅仅是支付，更是营销与服务的延续，分享整个平台商户的销售提成；便利银行服务，便利投融资服务。</p>
          </div>
          
        </div>
        <div class="affairs-item">
          <div>
            <p>税务服务</p>
            <p> 提供税务筹划、家族财富传承与隔离，企业形象与社会责任、慈善基金的服务。</p>
          </div>
          
        </div>
        <div class="affairs-item">
          <div>
            <p>法务服务</p>
            <p>提供股权设计、股权融资、股权激励、私募基金、并购重组；企业常年法律顾问及合规管理法律服务等。</p>
          </div>
          
        </div>
      </div>
      <div class="logistics">
        <div class="logistics-l">
          <div class="logistics-title">后勤保障服务</div>
          <p>智领优选企业服务平台</p>
          <p>01 —— 企业公司订餐系统、医院订餐系统（包括员工和病患订餐）<br /> 02 —— 会务服务（会议会务安排、礼仪庆典、住宿、纪念品）<br />03 —— 智慧园区、智慧院舍、智慧办公服务 (方案设计、施工、维护；物业租赁、购买) <br />04 —— 企业、公司智能安防服务 (停车场、人脸识别、人防安全等)<br />05 —— 物业管理服务<br />06 —— 办公设备采购、安装、维护、租赁、共享<br />07 —— 设备、生产原料采购、运输、储存、共享以及相关金融服务<br />08 —— 重要产品采购 (高档食材、茶叶、工艺品、企业礼品)<br />09 —— 环境安全服务 (环境安全方案、消毒、消杀服务、食品安全、厨房采购与外包)<br />10 —— 消防服务（消防报建、消防图纸设计、施工安装调试、消防验收及维护）</p>
          <div class="logistics-img">
            <img src="https://fxwebsite.rencaijituan.com/global/serve/img2.jpg" alt="">
          </div>
        </div>
        <div class="logistics-r">
          <div class="logistics-img">
            <img src="https://fxwebsite.rencaijituan.com/global/serve/img1.jpg" alt="">
          </div>
          <div class="logistics-title">项目服务</div>
          <p>智领项目服务</p>
          <p>01 —— 专利、商标、企业注册服务（注册、申报、转让、购买）<br />02 —— 项目申报、运营、督导与评估<br />03 —— 技术改造项目立项、补贴申请与金融服务<br />04 —— 招投标咨询、代理<br />05 —— 项目托管与外包（医养项目、技术改造生产外包、智能化改造等）<br />06 —— 生产线设计、改造、运营、融资、外包<br />07 —— 企业服务软件 (ERP、财务、销售等，授权使用、设计开发、维护、推广)<br />08 —— 企业组织系统建设，提高企业管理成熟度，持续企业利润</p>
        </div>
      </div> 
    </div>
    
</template>

<script>
  import Swiper from '@/components/swiper/Swiper.vue';
  
  export default {
    data() {
      return {
        src: {
          src: '/global/serve/banner.jpg'
        }
      };
    },
    components: {
      Swiper
    }
  }
</script>
<style lang="scss" scoped>
  $my_padding: percentage(260/1920);
  .container {
    margin-top: 100px;
    .software {
      .erp {
        background: url('https://fxwebsite.rencaijituan.com/global/serve/bg.png') no-repeat center center;
        background-size: 100% 100%;
        height: 35.625vw;
        padding: 0 $my_padding;
        overflow: hidden;
        .erp-title {
          font-size: 1.667vw;
          font-weight: 500;
          color: rgba(0, 0, 0, 0.85);
          margin: 4.1667vw 0 2.3958vw 0;
        }
        .erp-item {
          width: 33.333vw;
          > p:first-child {
            font-size: 1.25vw;
            font-weight: 500;
            color: rgba(0, 0, 0, 0.85);
            margin-bottom: 0.8333vw;
            position: relative;
            &::after {
              content: '';
              width: 24.7917vw;
              height: 1px;
              background-color: rgba(0, 0, 0, 0.1);
              position: absolute;
              top: 50%;
              right: 0;

            }
          }
          > p:last-child {
            font-size: 0.8333vw;
            font-weight: 400;
            line-height: 1.6667vw;
            color: rgba(0, 0, 0, 0.6);
            margin-bottom: 2.0833vw;
            
          }
          &:last-child {
            > p:first-child {
              &::after {
              content: '';
              width: 18.75vw;
              height: 1px;
              background-color: rgba(0, 0, 0, 0.1);
              position: absolute;
              top: 50%;
              right: 0;

              }
            }
          }
        }
      }
      .vip {
        background: url('https://fxwebsite.rencaijituan.com/global/serve/bg2.png') no-repeat center center;
        background-size: 100% 100%;
        padding: 0 $my_padding;
        height: 27.5vw;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        > div {
          width: 26.0417vw;
          .vip-title {
            font-size: 1.667vw;
            font-weight: 500;
            color: rgba(0, 0, 0, 0.85);
            margin-bottom: 2.3958vw;
          }
          > p:nth-child(2) {
            font-size: 1.25vw;
            font-weight: 500;
            color: rgba(0, 0, 0, 0.85);
            margin-bottom: 0.8333vw;
            position: relative;
            &::after {
              content: '';
              width: 14.6875vw;
              height: 1px;
              background-color: rgba(0, 0, 0, 0.1);
              position: absolute;
              top: 50%;
              right: 0;

            }
          }
          > p:last-child {
            font-size: 0.8333vw;
            font-weight: 400;
            line-height: 1.6667vw;
            color: rgba(0, 0, 0, 0.6);
            margin-bottom: 2.0833vw;
          }
        }
      }
      .security {
        background: url('https://fxwebsite.rencaijituan.com/global/serve/bg3.png') no-repeat center center;
        background-size: 100% 100%;
        height: 34.1667vw;
        padding: 0 $my_padding;
        overflow: hidden;
        /* overflow-y: scroll;
        &::-webkit-scrollbar {
        display: none;
        } */
        .security-title {
          font-size: 1.667vw;
          font-weight: 500;
          color: rgba(0, 0, 0, 0.85);
          margin: 4.1667vw 0 2.3958vw 0;
        }
        .security-content {
          display: flex;
          width: 60%;
          flex-wrap: wrap;
          // overflow-y: scroll;
          .security-item {
            margin-bottom: 2.083vw;
            margin-right: 2.083vw;
            .svg-icon {
              width: 2.1875vw;
              height: 2.1875vw;
              margin-bottom: 0.833vw;
              
            }
            > p:nth-child(2) {
              font-size: 1.25vw;
              font-weight: 500;
              color: rgba(0, 0, 0, 0.85);
              margin-bottom: 0.7292vw;
            }
            > p:last-child {
              font-size: 0.833vw;
              font-weight: 400;
              line-height: 1.6667vw;
              color: rgba(0, 0, 0, 0.6);
            }
            &:last-child {
              margin-right: 0;
            }
          }
        }
      }
    }
    .human-resources {
      margin-top: 124px;
      padding: 0 $my_padding;
      display: flex;
      justify-content: space-between;
      .resources-l {
        width: 47.92vw;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .resources-title {
          height: 12.5vw;
          width: 22.92vw;
          background: url('https://fxwebsite.rencaijituan.com/global/serve/bg-blue.png') no-repeat center center;
          background-size: 100% 100%;
          border-radius: 8px;
          box-sizing: border-box;
          padding: 1.67vw 0 0 2.08vw;
          margin-bottom: 2.083vw;
          > p {
            font-size: 1.67vw;
            font-weight: 500;
            color: #FFFFFF;
            
          }
        }
        .resources-item {
          height: 12.5vw;
          width: 22.92vw;
          background: url('https://fxwebsite.rencaijituan.com/global/serve/bg-yello.png') no-repeat center center;
          background-size: 100% 100%;
          border-radius: 8px;
          box-sizing: border-box;
          padding: 1.67vw 0 0 2.08vw;
          > p:first-child {
            font-size: 1.67vw;
            font-weight: 500;
            color: #FFFFFF;
            margin-bottom: 0.833vw;            
          }
          > p:last-child {
            font-size: 0.833vw;
            font-weight: 500;
            line-height: 1.67vw;
            color: #FFFFFF;
            
          }
          &:last-child {
            width: 47.92vw;
            background: url('https://fxwebsite.rencaijituan.com/global/serve/bg-Light-blue.png') no-repeat center center;
            background-size: 100% 100%;
          }
        }
      }
      .resources-r {
        width: 22.917vw;
        background: url('https://fxwebsite.rencaijituan.com/global/serve/bg-Light-green.png') no-repeat center center;
        background-size: 100% 100%;
        border-radius: 8px;
        box-sizing: border-box;
        padding: 1.67vw 0 0 2.08vw;
        > p:first-child {
          font-size: 1.67vw;
          font-weight: 500;
          color: #FFFFFF;
          margin-bottom: 0.833vw;            
        }
        > p:last-child {
          font-size: 0.833vw;
          font-weight: 500;
          line-height: 1.67vw;
          color: #FFFFFF;
          
        }
      }
    }
    .affairs {
      padding: 0 $my_padding;
      margin: 120px 0; 
      display: flex;
      justify-content: space-between;
      .affairs-item {
        width: 22.917vw;
        height: 31.25vw;
        background-color: gray;
        display: flex;
        align-items: flex-end;
        padding: 0 2.604vw 2.083vw 2.604vw;
        box-sizing: border-box;
        border-radius: 8px;
        > div {
          > p {
            color: #FFFFFF;
            &:first-child {
              font-size: 1.875vw;
              font-weight: 500;
            }
            &:last-child {
              font-size: 0.8333vw;
              font-weight: 400;
              line-height: 1.6667vw;
              margin-top: 0.8333vw;
            }
          }
        }
        &:nth-child(1) {
          background: url('https://fxwebsite.rencaijituan.com/global/serve/bg-img1.png') no-repeat center center;
          background-size: 100% 100%;
        }
        &:nth-child(2) {
          background: url('https://fxwebsite.rencaijituan.com/global/serve/bg-img2.png') no-repeat center center;
          background-size: 100% 100%;
        }
        &:nth-child(3) {
          background: url('https://fxwebsite.rencaijituan.com/global/serve/bg-img3.png') no-repeat center center;
          background-size: 100% 100%;
        }
      }
    }
    .logistics {
      padding: 0 $my_padding;
      display: flex;
      justify-content: space-between;
      margin-bottom: 124px;
      .logistics-l {
        width: 36.979vw;
        .logistics-title {
          font-size: 1.667vw;
          font-weight: 500;
          color: rgba(0, 0, 0, 0.85);
          margin-bottom: 2.083vw;
        }
        > p {
          &:nth-child(2) {
            font-size: 1.25vw;
            font-weight: 500;
            color: rgba(0, 0, 0, 0.85);
            margin-bottom: 0.8333vw;
            position: relative;
            &::after {
              content: '';
              width: 23.6458vw;
              height: 1px;
              background-color: rgba(0, 0, 0, 0.1);
              position: absolute;
              top: 50%;
              right: 0;

            }
          }
          &:nth-child(3) {
            font-size: 0.8333vw;
            font-weight: 400;
            line-height: 2vw;
            color: rgba(0, 0, 0, 0.6);
            margin-bottom: 2vw;
          }
        }
        .logistics-img {
          width: 100%;
          > img {
            width: 100%;
          }
        }
      }
      .logistics-r {
        width: 30.729vw;
        .logistics-img {
          width: 100%;
          margin-bottom: 1.667vw;
          > img {
            width: 100%;
          }
        }
        .logistics-title {
          font-size: 1.667vw;
          font-weight: 500;
          color: rgba(0, 0, 0, 0.85);
          margin-bottom: 2.083vw;
        }
        > p {
          &:nth-child(3) {
            font-size: 1.25vw;
            font-weight: 500;
            color: rgba(0, 0, 0, 0.85);
            margin-bottom: 0.8333vw;
            position: relative;
            &::after {
              content: '';
              width: 22.3958vw;
              height: 1px;
              background-color: rgba(0, 0, 0, 0.1);
              position: absolute;
              top: 50%;
              right: 0;

            }
          }
          &:last-child {
            font-size: 0.8333vw;
            font-weight: 400;
            line-height: 2vw;
            color: rgba(0, 0, 0, 0.6);
            // margin-bottom: 2vw;
          }
        }
      }
    }
  }
</style>