import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/Home'
import Residential from '@/views/residential/Residential'
import HomeCare from '@/views/homecare/HomeCare'
import CivilService from '@/views/CivilService'
import Hardware from '@/views/Hardware'
import Invest from '@/views/Invest'
import Serve from '@/views/Serve'
import News from '@/views/news/News'
import About from '@/views/About'
import Introduce from '@/views/homecare/Introduce'
import Offer from '@/views/homecare/Offer'
import RIntroduce from '@/views/residential/RIntroduce'
import ROffer from '@/views/residential/ROffer'
import NewsList from '@/views/news/NewsList'
import NewsDetail from '@/views/news/NewsDetail'
Vue.use(VueRouter);


const routes = [
  {
    path: '/',
    redirect: '/home',
  },
  {
    path: '/home',
    name: 'home',
    component: Home
  },
  {
    path: '/residential',
    name: 'residential',
    component: Residential,
    redirect: '/residential/rIntroduce/1',
    children: [
      {
        path: 'rIntroduce/:id',
        name: 'rIntroduce',
        props: true,
        component: RIntroduce
      },
      {
        path: 'rOffer',
        name: 'rOffer',
        component: ROffer
      }
    ]
  },
  {
    path: '/homeCare',
    name: 'homeCare',
    component: HomeCare,
    redirect: '/homeCare/introduce',
    children: [
      {
        path: 'introduce',
        name: 'introduce',
        component: Introduce
      },
      {
        path: 'offer',
        name: 'offer',
        component: Offer
      }
    ]
  },
  {
    path: '/civilService',
    name: 'civilService',
    component: CivilService
  },
  {
    path: '/hardware',
    name: 'hardware',
    component: Hardware
  },
  {
    path: '/invest',
    name: 'invest',
    component: Invest
  },
  {
    path: '/serve',
    name: 'serve',
    component: Serve
  },
  {

    path: '/news',
    redirect:'/news/newsList/1',
    name: 'news',
    component: News,
    children: [
      {
        path: 'newsList/:id',
        name: 'newsList',
        props: true,
        component: NewsList
      },
      {
        path: 'newsDetail/:id',
        name: 'newsDetail',
        props: true,
        component: NewsDetail
      }
    ]
  },
  {
    path: '/about',
    name: 'about',
    component: About
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
