<template>
  <el-container>
    <el-header>
      <img src="https://fxwebsite.rencaijituan.com/global/logo.png" alt="繁星医养">
      <el-menu :default-active="activeIndex" mode="horizontal" @select="handleSelect" text-color="#000000D9" active-text-color="#00ADF6" router>
        <el-menu-item index="/home">首页</el-menu-item>
        <el-submenu index="/product">
          <template slot="title">产品介绍</template>
          <el-menu-item index="/residential">智慧院舍系统</el-menu-item>
          <el-menu-item index="/homeCare">智慧居家养老系统</el-menu-item>
          <el-menu-item index="/civilService">民政服务系统</el-menu-item>
          <el-menu-item index="/hardware">智能硬件</el-menu-item>
        </el-submenu>
        <el-menu-item index="/invest">医养投资</el-menu-item>
        <el-menu-item index="/serve">政企服务</el-menu-item>
        <el-menu-item index="/news">新闻资讯</el-menu-item>
        <el-menu-item index="/about">关于我们</el-menu-item>
      </el-menu>
    </el-header>
    <el-main>
      <div class="float">
        <img src="https://fxwebsite.rencaijituan.com/global/floating.png" alt="">
      </div>
      <router-view></router-view>
    </el-main>
    <el-footer>
      <div class="footer-title">
        <img src="https://fxwebsite.rencaijituan.com/global/logo.png" alt="繁星医养">
        <div class="title-tips">
          <p>服务热线：0750-3676788<span>业务咨询：13302880921</span></p>
          <p>公司地址：江门市蓬江区杜阮镇江杜中路 29 号</p>
        </div>
      </div>
      <div class="footer-content">
        <div class="footer-link">
          <div class="friendship-link">
            <p>友情链接</p>
            <div>
              <a href="/residential">智慧院舍系统</a>
              <a href="/homeCare">智慧居家养老系统</a>
              <a href="/civilService">民政服务系统</a>
              <a href="/hardware">智能硬件</a>
              <a href="/invest">医养投资</a>
              <a href="/serve">政企服务</a>
              <a href="/news">新闻资讯</a>
              <a href="/about">关于我们</a>
            </div>
            
          </div>
          <p><a href="http://www.jiangmenrenai.com/" target="_blank">仁爱官网：http://www.jiangmenrenai.com/</a></p>
		  
        </div>
        <div class="scan-code">
          <div class="code-content">
            <img src="https://fxwebsite.rencaijituan.com/global/code2.png" alt="扫码">
            <div class="text">
              <p>更多项目详情</p>
              <p>欢迎扫码资讯</p>
            </div>
          </div>
          <div class="code-content">
            <img src="https://fxwebsite.rencaijituan.com/fxyy.jpg" alt="扫码">
            <div class="text">
              <p>业务联系</p>
              <p>13302880921</p>
            </div>
          </div>
        </div>
		
      </div>
    </el-footer>
	<div class="bottom">
<div class="bottom_text">广东繁星医养集团有限公司 版权所有</div>
<div class="bottom_text2">Copyright © 2014-2022 <a class="go" href="https://beian.miit.gov.cn/">粤ICP备18051025号</a></div>
</div>
</el-container>
</template>

<script>
import Vue from 'vue';
import { Container, Main, Footer, Header, Menu, MenuItem, Submenu } from 'element-ui';
Vue.use(Container);
Vue.use(Main);
Vue.use(Footer);
Vue.use(Header);
Vue.use(Menu);
Vue.use(MenuItem);
Vue.use(Submenu);

export default {
  name: 'Home',
  data() {
    return {
      //菜单栏的选择状态为当前路由
      activeIndex: '/' + this.$route.path.split('/')[1],
      scroll: 0
    };
  },


  watch: {

    /* 
      处理页面跳转滚动条回弹到顶部
    */
		$route: function(val) {
      //不需要滚动条回弹的子路由列表
      let notScollList = ['introduce', 'offer', 'rIntroduce', 'rOffer', 'newsList'];
      let isNoScoll =  notScollList.some(item => {
        if(val.name === item) {
          return true;
        }else {
          return false;
        }
      })
      // console.log(val);
      //从菜单默认跳转的子路由需要回弹
      let scroll1 = val.name === 'introduce' && val.redirectedFrom === '/homeCare';
      let scroll2 = val.name === 'rIntroduce' && val.redirectedFrom === '/residential';
      let scroll3 = val.name === 'newsList' && val.redirectedFrom === '/news';
      // console.log();

      if(scroll1 || scroll2 || scroll3 || !isNoScoll) {
        window.scrollTo(0,0);
      }
      
		},
	},
  created() {
    console.log(navigator.userAgent);
    this.isMobile();
  },
  
  mounted(){
    window.addEventListener('scroll', this.scrollTop)
    // console.log(this.$route.path.split('/')[1]);
    // console.log(this.scroll)
  },
  methods:{

    /**
   * 方法说明
   * header到顶部时变成透明，页面滚动后带有背景样式
   */
    scrollTop(){
      this.scroll = document.documentElement.scrollTop || document.body.scrollTop;
      // console.log(this.scroll)
      let elHeader = document.getElementsByClassName('el-header')[0];
      if(this.scroll !== 0 ){
        
        elHeader.classList.add('header-change');
      }else {
        if(elHeader.classList.contains('header-change') == true) {
          elHeader.classList.remove('header-change');
        }
      }

    },

    //菜单切换方法
    handleSelect(indexPath) {
      /* console.log(indexPath);
      if(indexPath == '/homeCare') {
        // this.$router.go(0);
      } */
    },

    //判断是否为pc端
    isMobile() {
      
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
      if(flag === null) {
        console.log('pc端');
      }else {
        console.log('移动端');
         window.location.href = "https://www.baidu.com/";
      }
      
      // return flag;
      
   }
  }

  
}
</script>

<style lang="scss" >
  $my_padding: percentage(260/1920);

  
  .el-container {
	  
	  .bottom{
		  width: 100%;
		  height: 150rpx;
		  background-color:#F8F8F8;
		  display: flex;
		  flex-direction: column;
		  .bottom_text{
			  color: #818181;
			  margin: auto;
		  }
		  .bottom_text2{
			  color: #818181;
			  margin: auto;
			  margin-top: 12px;
			  .go{
				  color: #409EFF;
				  text-decoration: underline;
			  }
		  }
	  }
	  
    .el-header {
      position: fixed;
      z-index: 999;
      width: 100%;
      
      height: 100px !important;
      padding: 0 $my_padding;
      display: flex;
      align-items: center;
      justify-content: space-between;
      // -webkit-box-shadow: 0 4px 16px rgba(0, 0, 0, 0.04);
      // -moz-box-shadow: 0 4px 16px rgba(0, 0, 0, 0.04);
      // box-shadow: 0 4px 16px rgba(0, 0, 0, 0.04);
      > img {
        width: 72px;
        height: 72px;
      }
      .el-menu {
        border: none;
        background: none;
        // height: 100%;
        .el-menu-item {
          // color: rgba(0, 0, 0, 0.85);
          font-size: 16px;
          font-weight: 400;
          font-style: normal;
          &.is-active {
            border-bottom: rgba(0, 0, 0, 0) !important;
            font-weight: 500;
          }
          
          
          
        }
        .el-menu-item:focus, .el-menu-item:hover {
          background: none;
        }
        .el-menu-item:hover {
          color: #00ADF6 !important;
        }
        > .el-submenu{
          
          
          .el-submenu__title {
              // color: rgba(0, 0, 0, 0.85);
              font-size: 16px;
              font-weight: 400;
              font-style: normal;

              &:hover {
              color: #00ADF6 !important;
              background: none !important;
              .el-submenu__icon-arrow {
                color: #00ADF6 !important;
              }
          } 
            }
          &.is-active {
            
            .el-submenu__title {
              border-bottom: rgba(0, 0, 0, 0) !important;
              .el-submenu__icon-arrow {
                color: #00ADF6 !important;
              }
            }
          }
          
        }
      }
      

    }
    .el-main {
      padding: 0;
      .float {
        width: 11.4583vw;
        height: 18.75vw;
        position: fixed;
        z-index: 9999;
        right: 0;
        top: 30.7292vw;
        > img {
          width: 100%;
          height: 100%;
        }
      }
    }
    .el-footer {
      height: 380px !important;
      padding: 0 $my_padding;
      background-color: #F8F8F8;
      .footer-title {
        height: 136px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        display: flex;
        align-items: center;
        justify-content: space-between;
        > img {
          width: 72px;
          height: 72px;
        }
        .title-tips {
          > p {
            color: rgba(0, 0, 0, 0.6);
            font-size: 0.7292vw;
            font-weight: 400;
            text-align: right;
          }
          > p:first-child {
            margin-bottom: 16px;
            > span {
              margin-left: 2.0833vw;
            }
          }
        }
      }
      .footer-content {
        display: flex;
        height: 244px;
        justify-content: space-between;
        align-items: center;
        .footer-link {
          a {
              &:hover {
                color: #00ADF6;
              }
          }
          .friendship-link {
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
            padding-bottom: 24px;
            // max-width: 688px;
            width: 35.8333vw;
            a {
              &:hover {
                color: #00ADF6;
              }
          }
            > p {
              font-size: 0.7292vw;
              font-weight: 400;
              color: rgba(0, 0, 0, 0.6);
            }
            > div {
              display: flex;
              justify-content: space-between;
              flex-wrap: wrap;
              > a {
                display: inline-block;
                width: 5.8333vw;
                margin: 1.25vw 11.5% 0 0;
                font-size: 0.7292vw;
                font-weight: 400;
                color: rgba(0, 0, 0, 0.6);
              }
              > a:nth-child(4),  > a:nth-child(8){
                margin-right: 0;
                width: 2.9167vw;
              }
            }
            
            
          }
          > p {
            margin-top: 1.25vw;
            font-size: 0.7292vw;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.6);
          }
        }
      }
      .scan-code {
        width: 28.75vw;
        // margin-top: 2.0833vw;
        display: flex;
        justify-content: space-between;
        .code-content {
          display: flex;
          align-items: center;
          > img {
            width: 5vw;
            height: 5vw;
          }
          .text {
            // width: 84px;
            color: rgba(0, 0, 0, 0.6);
            font-size: 0.8333vw;
            font-weight: 400;
            margin-left: 0.8333vw;
            line-height: 1.6667vw;
            
          }
        }
      }
    }
    .header-change {
      -webkit-box-shadow: 0 4px 16px rgba(0, 0, 0, 0.04);
      -moz-box-shadow: 0 4px 16px rgba(0, 0, 0, 0.04);
      box-shadow: 0 4px 16px rgba(0, 0, 0, 0.04);
      background-color: #ffffff;

    }
     
    
  }
  .el-menu--popup-bottom-start .el-menu-item:hover{
    color: #00ADF6 !important;
  }
  
  
  
  
  
</style>


