/* swiper轮播组件(banner) */

<template>
    <div class="swiper">
      <img :src="require('@/assets' + src.src)" alt="banner">
     
    </div>
</template>

<script>
    export default {
        
        /* 接收父组件参数，将来传入的是数组 type: Array */
        props: {
            src: Object
        },
        
    }
</script>

<style lang="scss" scoped>
    .swiper {
      width: 100%;
      height: 28.125vw;
      > img {
        width: 100%;
        // height: 100%;
      }
    }
</style>