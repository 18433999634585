<template>
  <div class="container">
    <!-- <swiper :src='src' /> -->
    <div class="banner">
      <img src="https://fxwebsite.rencaijituan.com/global/hardware/banner.jpg" alt="banner">
    </div>
    <div class="hardware-order">
      <div v-for="(item, index) in productList" :key="index" class="hardware-item">
        <div>
          <p>{{item.productName}}</p>
          <p>{{item.productSummary}}</p>
        </div>
        <img :src="item.productPhotoUrl" alt="">
      </div>
    </div>

  </div>
</template>

<script>
  export default {
    data() {
      return {
        productList: []
      };
    },

    mounted() {
      this.getProductList();
    },
    
    methods: {
      async getProductList() {
        let result = await this.$http.get({
            url: '/client/web/product/list'
        })
        
        let { rows } = result;
        // console.log(rows);
        this.productList = [...rows];
        // console.log(this.productList);
      },
    },
    
  }
</script>

<style lang="scss" scoped>
  $my_padding: percentage(260/1920);
  .container {
    margin-top: 100px;
    .banner {
      height: 25vw;
      > img {
        width: 100%;
      }
    }
    .hardware-order {
      padding: 0 $my_padding;
      margin: 120px 0;
      display: flex;
      justify-content: space-between;
        // justify-content: center;
      flex-wrap: wrap;
      .hardware-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 2.0833vw;
        box-sizing: border-box;
        background: rgba(0, 173, 246, 0.04);
        width: 35.4167vw;
        // min-width: 528px;
        height: 13.5417vw;
        margin-bottom: 2.0833vw;
        border-radius: 8px;
        >div {
          height: 100%;
          padding: 10px 0;
          max-width: 19.7917vw;
          box-sizing: border-box;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          > p:first-child {
            font-size: 1.25vw;
            font-weight: 500;
            // margin-bottom: 88px;
          }
          > p:last-child {
            font-size: 0.8333vw;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.6);
            line-height: 1.6667vw;
          }
        }
        >img {
          height: 9.375vw;
          width: 9.375vw;
        }
      }
    } 
  }
</style>