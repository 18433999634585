import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/reset.css'
import '@/assets/global/icons' // icon
import Http from '@/utils/http'



Vue.config.productionTip = false;
Vue.prototype.$http = new Http();

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
