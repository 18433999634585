<template>
  <div class="container">
    <swiper :src="src" />

    <div class="company-introduction">
      <div class="company-content">
        <div class="company-title">
          <span>公司介绍</span>
          <span>广东繁星医养集团有限公司</span>
        </div>
        <p>广东繁星医养集团创立于2014年，是一家以医养结合为特色，以医疗、养老机构投资和运营管理为主业，并涉足智慧医养软硬件开发、医养产品生产销售等医养整体解决方案和健康全产业链的专业化集团公司。</p>
        <p>在繁星医养集团全面快速高质量发展历程中，始终将自身定位于社会企业，立足中国人口老龄化实际，致力于养老机构的投资与营运，解决社会老年人的医养难题。近年来，繁星医养集团精准把握时代脉搏、紧抓中国养老发展机遇期，在做强医养服务版块的基础上，根据《养老机构等级划分与评定》国家标准进行设计、研发全场景医养服务智慧管理平台（内含：智慧院舍系统、智慧居家养老系统、民政服务系统、智能硬件）四大版块，涵盖了养老、医疗、康复、护理、评估、咨询、老年保健等各大领域，形成了医养服务产业聚群、公益创新协同发展的良好态势。</p>
        <p>集团旗下目前拥有江门市安老养老服务有限公司、江门市仁爱社会工作综合服务中心、江门蓬江安老养老医院，江门惠联乐怡诊所、江门惠联丰乐中医门诊，服务于医养结合与人群的健康管理。同时运营服务杜阮颐养院，四会医养项目、三水医养项目、佛山医养项目、广州医养项目等在当地标杆性的医养结合项目；目前服务辐射还包括重庆、四川、江西、广西、浙江等地区。</p>
        <p>依托繁星智慧医养系统，力争五年内使用机构超过500家，形成完善的医养服务体系，涵盖养老机构智能化、医养结合、人员培训、星级评定顾问服务、居家医养服务、政府层面监管服务、集团化发展财务法务税务金融等综合性的繁星医养服务平台。</p>
        <p>其中医养结合项目三年内规划在全国各地养老机构投资约40家一甲医院，形成“养老—护理—医疗”三位一体整合照料，打造养老院－医院“院中院”嵌入型医养结合服务模式，建立医养结合综合体，做一流医养服务提供商！</p>
      </div>
      <div class="company-tips">
        <div class="tips-item">
          <svg-icon icon-class="Professional"></svg-icon>
          <p>专业化</p>
          <p>多年医养经验.专业医护团队</p>
        </div>
        <div class="tips-item">
          <svg-icon icon-class="lth"></svg-icon>
          <p>立体化</p>
          <p>全国战略布局.涵盖多端养老模式</p>
        </div>
        <div class="tips-item">
          <svg-icon icon-class="dyh"></svg-icon>
          <p>多元化</p>
          <p>配置多元服务.满足养老需求</p>
        </div>
        <div class="tips-item">
          <svg-icon icon-class="standard"></svg-icon>
          <p>标准化</p>
          <p>参考国家标准研发.锤炼五星服务品质</p>
        </div>
      </div>
    </div>
    <div class="experience">
      <div class="experience-item">
        <span>2013年</span>
        <p>组建团队对国内养老项目进行实地考察与研究，深度研究养老企业商业运营模式</p>
      </div>
      <div class="experience-item">
        <span>2014年</span>
        <p>成立江门市安老养老服务有限公司，梳理战略发展规划、机构运营标准。</p>
      </div>
      <div class="experience-item">
        <span>2014年</span>
        <p>创立江门市仁爱社会工作综合服务中心，并带领团队全程筹划运营第一个公建民营项目杜阮颐养院。</p>
      </div>
      <div class="experience-item">
        <span>2015年</span>
        <p>接手运营鹤山慈善安老院</p>
      </div>
      <div class="experience-item">
        <span>2015年</span>
        <p>创立江海德寿颐养院</p>
      </div>
      <div class="experience-item">
        <span>2020年</span>
        <p>迅速反应、迅速防控、科学部署，实现了各机构入住老人、员工“零感染”。</p>
      </div>
      <div class="experience-item">
        <span>2021年</span>
        <p>创立江门惠联乐怡诊所、江门惠联丰乐中医门诊，继续潜心专耕，实践创新</p>
      </div>
    </div>
    <div class="map">
      <div class="map-item">
        <p class="map-title">经营理念</p>
        <p class="map-content">
          以服务为基础，以技术为支撑，以医养为方向，打造大健康产业链，建设大湾区医养结合示范区。
        </p>
      </div>
      <div class="map-item">
        <p class="map-title">服务理念</p>
        <p class="map-content">
          降低试错成本，提升安全系数，提高经济效益。
        </p>
      </div>
      <div class="map-item">
        <p class="map-title">企业使命</p>
        <p class="map-content">
          致力于成为中国医养服务行业引领者，打造养老产业的领军品牌。
        </p>
      </div>
      <div class="map-item">
        <p class="map-title">愿景</p>
        <p class="map-content">
          立足江门，辐射全国，创立中国一流的养老服务品牌，携手中国各家养老机构、养老社区连锁发展，共同开创中国养老事业的新天地。
        </p>
      </div>
    </div>
    <div class="honour">
      <div class="honour-title">公司荣耀</div>
      <el-carousel :interval="4000" type="card" height="31.25vw" indicator-position="none" 
      >
        <el-carousel-item v-for="item in honourList" :key="item.id">
          <img :src="item.url" alt="">
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="location">
      <div class="location-l" id="bmap">
        <!-- 百度地图api -->
      </div>
      <div class="location-r">
        <p>广东繁星医养集团有限公司</p>
        <p>服务热线：0750-3676788</p>
        <p>公司地址：江门市蓬江区杜阮镇江杜中路 29 号</p>
      </div>
    </div>
  </div>
</template>
<script>
  import Vue from 'vue';
  import { Carousel, CarouselItem } from 'element-ui'
  Vue.use(Carousel);
  Vue.use(CarouselItem);
  //引入百度地图
  import BMap from 'BMap';
  import Swiper from '@/components/swiper/Swiper.vue';

  export default {
    data() {
      return {
        src: {
          src: '/global/about/banner.jpg'
        },
        honourList: [
          {
            id: 0, 
            url: ('https://fxwebsite.rencaijituan.com/global/about/honour1.jpg')
          },
          {
            id: 1, 
            url: ('https://fxwebsite.rencaijituan.com/global/about/honour2.jpg')
          },
          {
            id: 2, 
            url: 'https://fxwebsite.rencaijituan.com/global/about/honour3.jpg'
          },
          {
            id: 3, 
            url: 'https://fxwebsite.rencaijituan.com/global/about/honour1.jpg'
          },
          {
            id: 4, 
            url: 'https://fxwebsite.rencaijituan.com/global/about/honour2.jpg'
          },
          {
            id: 5, 
            url: 'https://fxwebsite.rencaijituan.com/global/about/honour3.jpg'
          },


        ],
        index: ''
      };
    },
    components: {
      Swiper
    },
    mounted() {
      this.ready()
    },
    
    methods: {
      //百度地图接口
      ready() {
      var map = new BMap.Map('bmap');
      var point = new BMap.Point(113.02884,22.608145);
      map.centerAndZoom(point, 20);
      // map.addControl(new BMap.MapTypeControl());
      map.enableScrollWheelZoom(true);
      map.enableDoubleClickZoom(true);
      var marker = new BMap.Marker(point);
      map.addOverlay(marker);
    }
    },
  }
</script>

<style lang="scss" scoped>
  $my_padding: percentage(260/1920);
  .container {
      margin-top: 100px;
      .company-introduction {
        height: 51.875vw;
        // width: 100%;
        background: url('https://fxwebsite.rencaijituan.com/global/about/bg.png') no-repeat center center;
        background-size: 100% 100%;
        padding: 0 $my_padding;
        overflow: hidden;
        .company-content {
          .company-title {
            margin: 6.25vw 0 2.0833vw 0;
            display: flex;
            justify-content: space-between;
            align-items: center;
            > span:first-child {
              font-size: 1.6667vw;
              font-weight: 500;
              color: rgba(0, 0, 0, 0.85);
            }
            > span:last-child {
              font-size: 1.0417vw;
              font-weight: 500;
              color: rgba(0, 0, 0, 0.85);
              position: relative;
              &::before {
                content: '';
                width: 47.9167vw;
                height: 1px;
                position: absolute;
                top: 50%;
                background-color: rgba(0, 0, 0, 0.1);
                right: 13vw;
              }
            }
          }
          > p {
            color: rgba(0, 0, 0, 0.6);
            font-size: 0.8333vw;
            font-weight: 400;
            line-height: 1.6667vw;
            margin-bottom: 1.25vw;
          }
        }
        .company-tips {
          width: 100%;
          background-color: rgba(255, 255, 255, 1);
          height: 10.3125vw;
          border-radius: 8px;
          margin-top: 3.3333vw;
          -webkit-box-shadow: 0 8px 24px rgba(0, 0, 0, 0.04);
          -moz-box-shadow: 0 8px 24px rgba(0, 0, 0, 0.04);
          box-shadow: 0 8px 24px rgba(0, 0, 0, 0.04);
          display: flex;
          justify-content: space-around;
          align-items: center;
          .tips-item {
            .svg-icon {
              width: 2.5vw;
              height: 2.5vw;
              display: block;
              margin: 0 auto;
              margin-bottom: 0.8333vw;
              
            }
            > p {
              font-size: 0.9375vw;
              font-weight: 500;
              color: rgba(0, 0, 0, 0.85);
              text-align: center;
              &:last-child {
                font-size: 0.8333vw;
                font-weight: 400;
                color: rgba(0, 0, 0, 0.6);
                margin-top: 0.42vw;
              }
            }
          }
        }
      }
      .experience {
        padding: 0 $my_padding;
        height: 20.8333vw;
        padding-top: 3.5417vw;
        background: url('https://fxwebsite.rencaijituan.com/global/about/bg1.png') no-repeat center center;
        background-size: 100% 100%;
        box-sizing: border-box;
        display: flex;
        justify-content: space-around;
        .experience-item {
          width: 8.3333vw;
          > span {
            font-size: 1.25vw;
            margin-bottom: 1.7188vw;
            font-weight: 500;
            color: #FFFFFF;
            display: block;
            position: relative;
            &::after {
              content: '';
              width: 0.8333vw;
              height: 1px;
              background-color: #FFFFFF;
              position: absolute;
              left: 0;
              bottom: -0.8333vw;
            }
          }
          > p {
            font-size: 0.8333vw;
            font-weight: 500;
            line-height: 1.6667vw;
            color: #FFFFFF;

          }
          &:nth-child(2), &:nth-child(4), &:nth-child(6) {
            margin-top: 2.5vw;
          }
        }
      }
      .map {
        height: 45.8333vw;
        background: url('https://fxwebsite.rencaijituan.com/global/about/map.png') no-repeat center center;
        background-size: 100% 100%;
        padding: 0 $my_padding;
        position: relative;
        box-sizing: border-box;
        .map-item {
          width: 18.125vw;
          box-sizing: border-box;
          padding: 1.25vw;
          background: linear-gradient(rgba(108, 212, 238, 1), rgba(0, 173, 246, 1));
          border-radius: 8px;
          position: relative;
          /* position: absolute;
          top: 208px; */
          &::before {
            content: '';
            height: 14.5833vw;
            width: 2.5vw;
            background: url('https://fxwebsite.rencaijituan.com/global/about/flat.png') no-repeat center center;
            background-size: 100% 100%;
            position: absolute;
            left: -2.5vw;
            bottom: 0;
          }
          .map-title {
            font-size: 1.25vw;
            font-weight: 500;
            color: #FFFFFF;
            margin-bottom: 1.0417vw;
          }
          .map-content {
            font-size: 0.8333vw;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 1.6667vw;
          }
          &:nth-child(1) {
            position: absolute;
            top: 10.8333vw;
            left: 16.0417vw;
            &::before {
              bottom: 1.25vw;
            }
          }
          &:nth-child(2) {
            position: absolute;
            top: 20.9375vw;
            left: 37.9167vw;
            .map-content {
              font-size: 0.7292vw;
            }
          }
          &:nth-child(3) {
            position: absolute;
            top: 9.2188vw;
            right: 24.4792vw;
          }
          &:nth-child(4) {
            position: absolute;
            bottom: 11.5625vw;
            right: 13.5417vw;
            &::before {
              bottom: 1.25vw;
            }
          }
        }
      }
      .honour {
        padding: 0 $my_padding;
        text-align: center;
        // height: 33.3333vw;
        overflow: hidden;
        .honour-title {
          font-size: 1.6667vw;
          font-weight: 500;
          color: rgba(0, 0, 0, 0.85);
          margin-bottom: 3.125vw;
          text-align: left;
        }
        .el-carousel__item {
          /* display: flex !important;
          justify-content: center;
          align-items: flex-start; */
          overflow: hidden;
          > img {
            height: 100%;
          }
        }
      }
      .location {
        margin: 120px 0; 
        padding: 0 $my_padding;
        display: flex;
        
        .location-l {
          width: 42.7083vw;
          height: 20.8333vw;
          border-radius: 8px 0 0 8px;
          -webkit-box-shadow: 0 16px 40px rgba(0, 0, 0, 0.04);
          -moz-box-shadow: 0 16px 40px rgba(0, 0, 0, 0.04);
          box-shadow: 0 16px 40px rgba(0, 0, 0, 0.04);
        }
        .location-r {
          width: 30.2083vw;
          box-sizing: border-box;
          border-radius: 0 8px 8px 0;
          padding: 6.7708vw 0 0 5.2083vw;
          -webkit-box-shadow: 0 16px 40px rgba(0, 0, 0, 0.04);
          -moz-box-shadow: 0 16px 40px rgba(0, 0, 0, 0.04);
          box-shadow: 0 16px 40px rgba(0, 0, 0, 0.04);
          > p {
            font-size: 0.8333vw;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.85);
            margin-bottom: 0.8333vw;
            &:first-child {
              font-size: 1.25vw;
              margin-bottom: 1.6667vw;
            }
          }
        }
      }
  }
</style>